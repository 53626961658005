import {
   SET_FRIENDS
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setFriends(friends) {
	return {
		type: SET_FRIENDS,
		friends: friends
	};
}

export function getFriends(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/friends`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(setFriends(data.data.friends));
		});
	}
}

export function getProfilesFriends(sub, token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/profiles/friends`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	 'sub': sub
		  }
		});
	};
}

export function createFriend(friend, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/friends`, friend, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}
