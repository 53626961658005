import {
    SET_SELECTED_LOCATION, SET_LOCATIONS, INITIAL_LOCATIONS_FETCH
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';


export function setSelectedLocation(selectedLocation) {
	return {
		type: SET_SELECTED_LOCATION,
		selectedLocation: selectedLocation
	};
}

export function initialLocationsFetch() {
	return {
		type: INITIAL_LOCATIONS_FETCH
	}
}

export function setLocations(locations) {
	return {
		type: SET_LOCATIONS,
		locations: locations
	};
}

export function getLocations(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/location`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			var fc = {
			  "type": "FeatureCollection",
			  "features": data.data.locations
			};
			dispatch(setLocations(fc));
		});
	}
}

export function createLocation(location, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/location`, location, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function deleteLocation(location_id, token) {
	return function(dispatch, getState) {
		return axios.delete(`${API_URL}/location`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	location_id: location_id
		  }
		});
	}
}