import React from "react";
import { Carousel, Row, Col, Empty } from 'antd';

const PostPopUp = (props) => {

    const images = props.feature.properties.images.replace('[', '').replace(']', '').replaceAll('"','').split(',').filter(function(image){
        return image !== "";
    });

    return (
    	<div onClick={props.handler} style={{maxWidth: '250px'}}>
   			<Row align="middle">
		      <Col span={24} style={{textAlign: 'left'}}>
		      	<p style={{margin: 0}}><strong>{props.feature.properties.title}</strong></p>
		      	{props.feature.properties.name}
		      </Col>
		    </Row>
       		{ images.length ? <Carousel>
       			<div>
    				<h3 style={{
    					width: '100%', 
    					height: '150px',
    					backgroundImage: 'url("'+ images[0] + '")',
    					backgroundSize: 'cover'
    				}}>
    				</h3>
    			</div>
    			<div>
    				<h3 style={{
    					width: '100%', 
    					height: '150px',
    					backgroundImage: 'url("'+ (images[1] ? images[1] : images[0]) + '")',
    					backgroundSize: 'cover'
    				}}>
    				</h3>
    			</div>
       		</Carousel> : null }
            {
                !images.length ? <Empty description={false}/> : null
            }
            <Row>
                <strong style={{lineHeight: '10px', textAlign: 'left'}}> 
                    {props.feature.properties.content.slice(0, 100)} 
                    {props.feature.properties.content.length > 100 ? '...' : null}
                </strong>
            </Row>
   		</div>
    )
}


export default PostPopUp;