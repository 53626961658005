import {
   SET_DEALS
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setDeals(deals) {
	return {
		type: SET_DEALS,
		deals: deals
	};
}

export function getDeals(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/deals`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(setDeals(data.data.deals));
		});
	}
}