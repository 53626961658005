import React, { useEffect, useState, useRef } from 'react';
import { Layout } from 'antd';
import './sidebar.css';
import {useSelector, useDispatch} from "react-redux";
import UncollapsedContent from './uncollapsedContent'
import CollapsedContent from './collapsedContent'
import { setSidebarStatus } from "../../actions/sidebar";
import { raiseResizeFlag } from "../../actions/map";

const { Sider } = Layout;

const Sidebar = () => {

  const collapsed = useSelector(state => state.sidebar.sidebarStatus)
  const isResizing = useRef(false);
  const dispatchLock = useRef(false);
  const [width, setWidth] = useState(350);
  const dispatch = useDispatch();

  const draggerStyles = {
      width: '5px',
      cursor: 'ew-resize',
      padding: '4px 0 0',
      borderTop: '1px solid #ddd',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: '100',
      backgroundColor: 'transparent'
  }

  const handleMousedown = (e) => {
      e.preventDefault();
      isResizing.current = true;
  };

  useEffect(() => {
    const handleMouseup = (e) => {
      isResizing.current = false;
    };
    document.addEventListener('mouseup', handleMouseup);
  });

  useEffect(() => {
    const handleMousemove = (e) => {
      if (!isResizing.current) {
        return;
      }

      let offsetRight = e.clientX;
      if (collapsed && !dispatchLock.current){
        dispatchLock.current = true;
        dispatch(setSidebarStatus(false));
        dispatchLock.current = false;
      } else if(offsetRight > 330){
        setWidth(offsetRight);
      } else {
        if(!collapsed && !dispatchLock.current){
          dispatchLock.current = true;
          dispatch(setSidebarStatus(true));
          dispatch(raiseResizeFlag());
          setTimeout(function(){
            dispatchLock.current = false;
          }, 100);
        }
      }
    };
    document.addEventListener('mousemove', handleMousemove);
  });

  const onCollapse = collapsed => {
    if(collapsed){
      dispatch(raiseResizeFlag())
    }
    dispatch(setSidebarStatus(collapsed))
  };

  return (
      <Sider style={{position: 'fixed', borderTopRightRadius: '5px', borderBottomRightRadius: '10px'}} width={width} className="sider hide-sm" collapsible collapsed={collapsed || dispatchLock.current} onCollapse={onCollapse}>
        <div
          id="dragger"
          onMouseDown={event => {
            handleMousedown(event);
          }}
          style={draggerStyles}
        />
        { collapsed ? <CollapsedContent/> : <UncollapsedContent/> }
      </Sider>
  );
}

export default Sidebar;
