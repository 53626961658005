import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Image, Typography, Modal, Form, Input, DatePicker, Rate } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from '@ant-design/icons';
import { setMapGrid } from "../../../actions/mapgrid";
import { createReservation } from "../../../actions/reservations";
import { useAuth0 } from "@auth0/auth0-react";
import { getReviews } from "../../../actions/reviews";
import Comments from "../comments";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const Location = () => {

	const selectedLocation = useSelector(state => state.locations.selectedLocation);
	const token = useSelector(state => state.token.token);
	const reviews = useSelector(state => state.reviews.reviews);
	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const mapgrid = useSelector(state => state.mapGrid.mapgrid);
	const [form] = Form.useForm();
	const {
	    user
	} = useAuth0();

	const handleOk = (e) => {
		setModal(false);
	};

	const handleCancel = (e) => {
		setModal(false);
	};

	useEffect(() => {
		if ( !selectedLocation ) {
			return
		}
		dispatch(getReviews(token, selectedLocation.properties.location_id))
		// eslint-disable-next-line
	}, [selectedLocation]);

	const layout = {
	  labelCol: {
	    span: 4,
	  },
	  wrapperCol: {
	    span: 20,
	  },
	};

	const submit = (values) => {
		const {
			dates,
			note
		} = values;
		let reservation = {
			startDate: dates[0],
			endDate: dates[0],
			note: note,
			location: selectedLocation.properties.name,
			requestingUser: user.email
		}
		dispatch(createReservation(reservation, token)).then(function(){
			setModal(false);
		});
	}

	return (
		<React.Fragment>
			<div className="sidebar-offset" style={{ textAlign: 'left', width: '100%', overflowY: 'scroll', display: (mapgrid === 'location' ? null : 'none')}}>
			<Button 
				style={{float: 'right', zIndex: 10}}
				size='large'
				icon={<CloseOutlined/>}
				onClick={() => {dispatch(setMapGrid('map'))}}
			/>
			{ selectedLocation ? <Row style={{borderBottom: '3px solid #D6D6D6', marginBottom: 12}}>

				<Title level={2} style={{textAlign: 'left', color: 'black'}}>
					{selectedLocation.properties.name}
				</Title>
				<Rate style={{fontSize: '24px', float: 'left', paddingLeft: 24, paddingRight: 8, paddingBottom: 8}} allowHalf disabled={true} defaultValue={selectedLocation.properties.rating}/>
				<Title level={5} style={{textAlign: 'left', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					{reviews.length} reviews
				</Title>
			</Row>: null }
			{ selectedLocation ? <Row gutter={[16, 16]} style={{width: '100%'}}>
				<Col span={16}>
					<Image style={{maxHeight: '63vh', overflow: 'hidden', borderRadius: 10}} src={selectedLocation.properties.images[0]}/>
				</Col>
				<Col span={8} gutter={[16, 16]}>
					<Image style={{maxHeight: '30vh', overflow: 'hidden', borderRadius: 10}}src={selectedLocation.properties.images[1]}/>
					<Image style={{marginTop: 12, maxHeight: '30vh', overflow: 'hidden', borderRadius: 10}} src={selectedLocation.properties.images[2]}/>
				</Col>
				
			</Row> : null }
			<div style={{paddingRight: '15%'}}>
				{ selectedLocation ? 
					<Typography>
						<Title level={4} style={{textAlign: 'left', letterSpacing: 5}}> 
							{selectedLocation.properties.guestNo} Guests &#183;
							{' ' + selectedLocation.properties.bedroomNo} Bedrooms &#183;
							{' ' + selectedLocation.properties.bathroomNo} Bathrooms &#183;
							{' ' + selectedLocation.properties.bedType} Bed 
							<Button onClick={() => setModal(true)} style={{marginLeft: '16px'}} type="primary">Reserve Now</Button>
						</Title>
						<Paragraph style={{textAlign: 'left'}}>
					      {selectedLocation.properties.description}
					    </Paragraph>
					</Typography> : null
				}
				{ selectedLocation ? <Comments parentId={selectedLocation.properties.location_id}/> : null }
				</div>
			</div>
			<Modal
				title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						New Reservation
			  		</div>
				}
				visible={modal}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button key="submit" form="newReservationForm" type="primary" onClick={form.submit}>
		              Request
		            </Button>
		        ]}
		   >
		   	<Form
		   	{...layout}
		   	id="newReservationForm"
		    form={form}
		    onFinish={submit}
		   >
			  <Form.Item
		        label="Dates"
		        name="dates"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <RangePicker />
		      </Form.Item>
		      <Form.Item
		        label="Note"
		        name="note"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <TextArea rows={4} />
		      </Form.Item>
		    </Form>
				
			</Modal>
		</React.Fragment>
	)
}

export default Location;