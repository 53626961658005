import {
    SET_COUNTRIES
} from '../constants/action-types'

let initialState = {
    countries: []
};

export default function countries(state=initialState, action) {
    switch (action.type) {
        case SET_COUNTRIES: {
            return Object.assign({}, state, {
                countries: action.countries
            });
        }
        default:
            return state
    }
}
