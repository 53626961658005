import {
    ADD_REVIEWS
} from '../constants/action-types'

let initialState = {reviews: []};

export default function reviews(state=initialState, action) {
    switch (action.type) {
        case ADD_REVIEWS: {
            console.log(action)
            return Object.assign({}, state, {
                reviews: action.reviews
            });
        }
        default:
            return state
    }
}
