import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function getStays(trip_id, token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/stays`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	trip_id: trip_id
		  }
		});
	}
}

export function createStay(stay, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/stays`, stay, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}