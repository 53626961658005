import {
	SET_TRIPS, SET_SELECTED_TRIP
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setTrips(trips) {
	return {
		type: SET_TRIPS,
		trips: trips
	};
}

export function setSelectedTrip(trip) {
	return {
		type: SET_SELECTED_TRIP,
		selectedTrip: trip
	};
}

export function getTrips(token, sub) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/trips`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }, 
		  params: {
		  	sub
		  }
		});
	}
}

export function createTrip(trip, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/trips`, trip, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}