import { Input } from "antd";
import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const LocationSearch = (props) => {

	const [address, setAddress] = useState('')

	const changeAddress = function(e) {
		setAddress(e)
	}

	const selectAddress = function(e) {
		geocodeByAddress(e).then(results => getLatLng(results[0])).then(function(latLng){
			props.setAddress(e, latLng);
		});
		setAddress(e);
	}


	return (
 		<PlacesAutocomplete onChange={(e) => changeAddress(e)} onSelect={(e) => selectAddress(e)} value={address}>
	 		{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
	          <div>
	            <Input
	              {...getInputProps({
	                placeholder: 'Search Places ...',
	                className: 'location-search-input',
	              })}
	            />
	            <div className="autocomplete-dropdown-container">
	              {loading && <div>Loading...</div>}
	              {suggestions.map((suggestion, idx) => {
	                const className = suggestion.active
	                  ? 'suggestion-item--active'
	                  : 'suggestion-item';
	                // inline style for demonstration purpose
	                const style = suggestion.active
	                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
	                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
	                return (
	                  <div
	                    {...getSuggestionItemProps(suggestion, {
	                      className,
	                      style,
	                    })}
	                    key={idx}
	                  >
	                    <span>{suggestion.description}</span>
	                  </div>
	                );
	              })}
	            </div>
	          </div>
	        )}
 		</PlacesAutocomplete>
	)
}

export default LocationSearch;