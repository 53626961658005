import {
    SET_ON_CLICK, SET_MINI_MAP, RAISE_RESIZE_FLAG, LOWER_RESIZE_FLAG, SET_MAP, SET_GEOCODER, SET_FEATURES_IN_VIEW
} from '../constants/action-types'

let initialState = {
    onClicks: {
        normal: (e, features, params) => {
            if(features.length){
                const images = features[0].properties.images.replace('[', '').replace(']', '').replaceAll('"','').split(',')
                features[0].properties.images = images;
                params.dispatch(params.setSidebarStatus(false));
                params.dispatch(params.setSelectedLocation(features[0]));
                params.dispatch(params.setSidebarTab('locations'));
            }
        }
    },
    resizeFlag: false,
    featuresInView: [],
    map: null,
    miniMap: null,
    geocoder: null
};

initialState.onClick = initialState.onClicks.normal

export default function sidebar(state=initialState, action) {
    switch (action.type) {
        case SET_ON_CLICK: {
            return Object.assign({}, state, {
                onClick: action.onClick
            });
        }
        case RAISE_RESIZE_FLAG: {
            return Object.assign({}, state, {
                resizeFlag: action.resizeFlag
            });
        }
        case SET_MAP: {
            return Object.assign({}, state, {
                map: action.map
            });
        }
        case SET_MINI_MAP: {
            return Object.assign({}, state, {
                miniMap: action.miniMap
            });
        }
        case SET_GEOCODER: {
            return Object.assign({}, state, {
                geocoder: action.geocoder
            });
        }
        case LOWER_RESIZE_FLAG: {
            return Object.assign({}, state, {
                resizeFlag: action.resizeFlag
            });
        }
        case SET_FEATURES_IN_VIEW: {
            return Object.assign({}, state, {
                featuresInView: action.featuresInView
            });
        }
        default:
            return state
    }
}
