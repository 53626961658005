import {
    SET_DEALS
} from '../constants/action-types'

let initialState = {
    deals: null
};

export default function deals(state=initialState, action) {
    switch (action.type) {
        case SET_DEALS: {
            return Object.assign({}, state, {
                deals: action.deals
            });
        }
        default:
            return state
    }
}
