import React from 'react';
import './home.css';
import Map from './map/map.js';
import Location from './location/location.js';
import Post from './post/post.js';
import Discussion from './discussion/discussion.js';
import Profile from './profile/profile.js';

const Home = () => {
	return (
		<React.Fragment>
			<Map/> 
			<Location/>
			<Post/>
			<Discussion/>
			<Profile/>
		</React.Fragment>
	)
}

export default Home;