import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import { Comment, Input, Avatar, List, Typography, Collapse, Row, Col, Tooltip } from 'antd';
import { getConversations } from "../../../actions/conversations";
import { setModal } from "../../../actions/modals";
import { setSelectedDiscussion, deleteDiscussion, getDiscussions } from "../../../actions/discussions";
import { setMapGrid } from "../../../actions/mapgrid";
import { setSidebarStatus } from "../../../actions/sidebar";
import { setTriggerStatus } from '../../../actions/trigger.js'
import { deletePost, getPosts, setSelectedPost } from "../../../actions/posts";
import { setSelectedProfile } from "../../../actions/profile";
import { PlusOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { Search } = Input;

const { Title } = Typography;
const { Panel } = Collapse;

const ConversationsTab = () => {

	const conversations = useSelector(state => state.conversations.conversations);
	const discussions = useSelector(state => state.discussions.discussions);
	const permissions = useSelector(state => state.permissions.permissions);
	const profiles = useSelector(state => state.profile.profiles);
	const posts = useSelector(state => state.posts.posts);
	const token = useSelector(state => state.token.token);
	const locationlessPosts = useSelector(state => state.posts.locationlessPosts);
	const [seeAllPosts, setSeeAllPosts] = useState(false);
	const [seeAllDiscussions, setSeeAllDiscussions] = useState(false);
	const [seeAllPeople, setSeeAllPeople] = useState(false);
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState("");

    const selectDiscussion = (discussion) => {
    	dispatch(setMapGrid('discussion'));
    	dispatch(setSelectedDiscussion(discussion));
    	dispatch(setTriggerStatus(false));
    	dispatch(setSidebarStatus(true));
    }

    const selectPost = (post) => {
    	dispatch(setMapGrid('post'));
    	dispatch(setSelectedPost(post));
    	dispatch(setTriggerStatus(false));
    	dispatch(setSidebarStatus(true));
    }

    const onSearch = (search) => {
    	setSearchText(search.target.value.toLowerCase());
    }

	useEffect(() => {
		if(!conversations.length){
			dispatch(getConversations(token)).then(function(){
				dispatch(getPosts(token))
			})
		}
	});

	const removePost = (post) => {
		dispatch(deletePost(post.properties.post_id, token)).then(function(){
			dispatch(getPosts(token));
		})
	}

	const removeDiscussion = async (discussion) => {
		dispatch(deleteDiscussion(discussion.discussion_id, token)).then(function(){
			dispatch(getDiscussions(token));
		});
	}
	
	return(
		<div className="tab-container">
			<div className="tab-content" >
				<Title level={4} style={{fontWeight: 'bold', letterSpacing: 3}}>Community Content</Title>
				<Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4']} style={{backgroundColor: '#F5F5F5'}}>
					<Panel header={<p><strong>People</strong> <Tooltip placement="right" title={'ⓘ Browse or search for profiles and connect with other NOMAD members. Follow profiles to receive notifications when they post trips, content, & more. '}><InfoCircleOutlined /> </Tooltip><span onClick={(e) => {e.stopPropagation(); setSeeAllPeople(true)}} style={{float: 'right'}}> { seeAllPeople ? null : 'See all >'} </span></p>} key="4">
						  <Search placeholder="Search People" allowClear onSearch={onSearch} onChange={onSearch} style={{ width: '100%' }} />
					      <List
						    itemLayout="horizontal"
						    dataSource={profiles.filter(function(profile){
						    	if(searchText === "") {
						    		return true;
						    	} else {
						    		const messString = profile.email + profile.name;
						    		return messString.indexOf(searchText.toLowerCase()) > -1;
						    	}
						    }).slice(0, seeAllPeople ? profiles.length : 3)}
						    renderItem={item => (
						      <List.Item
						      	style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5, color: 'white'}}
						      	onClick={() => {
						      		dispatch(setSelectedProfile(item));
						      		dispatch(setMapGrid('profile'));
						      		dispatch(setSidebarStatus(true));
						      		dispatch(setTriggerStatus(false));
						      	}}
						      >
						        <List.Item.Meta
						          avatar={<Avatar src={item.profile || './nomad-logo-square.png'} />}
						          title={item.name}
						          description={<span style={{opacity: 0.5}}>@{item.nickname}</span>}
						          style={{color: 'white'}}
						        />
						      </List.Item>
						    )}
						  />
				    </Panel>
				    <Panel header={<p><strong>Public Posts </strong><PlusOutlined onClick={(e) => {e.stopPropagation(); dispatch(setModal('travelPin'))}}/><Tooltip placement="right" title={'ⓘ Public Posts are visible by the public and displayed on the public NOMAD world-map. Help other NOMADs by giving them insider-info, tips, tricks & guides to certain establishments, co-working / co-living spaces, cafes, fun places to go, etc. They will show up as blue pins (only if “location” is selected), and can be commented on by other NOMAD members. Unlike Personal Posts, Public Posts are NOT *for your eyes only* - so only post what you want the entire community to know!'}><InfoCircleOutlined style={{marginLeft: 4}}/></Tooltip><span onClick={(e) => {e.stopPropagation(); setSeeAllPosts(true)}} style={{float: 'right'}}> { seeAllPosts ? null : 'See all >'} </span></p>} key="1">
				      {
				       posts ? (posts.features.concat(locationlessPosts.features)).slice(0, seeAllPosts ? posts.length : 1).filter(function(post){
				       		return post.properties.public
				       }).map(function(post, idx){
				      		return <Comment
				      				  style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5}}
								      author={
								      	<Col>
									      	<p style={{color: 'white', marginBottom: 0}}>
									      		<strong style={{fontSize: 16, letterSpacing: 3}}>{post.properties.title}</strong> <br/>
									      		<span style={{color: '#797979'}}>post &#183; {post.properties.name} </span>
									        </p>

								        </Col>
								  	  }
								      content={
								        <p style={{color: 'white'}}>
								          {post.properties.content.substring(0, seeAllPosts ? post.properties.content.length : 200 )} {(post.properties.content.length > 200 && !seeAllPosts) ? '...' : null} <br/>
								         { permissions.admin ? <DeleteOutlined style={{float: 'right', marginRight: 12}} onClick={() => removePost(post)}/> : null }
								        </p>
								      }
								      onClick={() => selectPost(post)}
								      key={idx}
								    />
				      	}) : null
				      }
				    </Panel>
				    <Panel style={{display: 'none'}} header={<p><strong>Discussions</strong> <PlusOutlined onClick={(e) => {e.stopPropagation(); dispatch(setModal('discussion'))}}/><span onClick={(e) => {e.stopPropagation(); setSeeAllDiscussions(true)}} style={{float: 'right'}}> { seeAllDiscussions ? null : 'See all >'} </span></p>} key="2">
				      {
							discussions.slice(0, seeAllDiscussions ? discussions.length : 1).map(function(discussion, idx){
								return <Comment
										  style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5}}
									      author={
									      	<Row>
									      	<Avatar
									          size={40}
									          src={discussion.user_avatar}
									          alt={discussion.name}
									        />
									      	<p style={{color: 'white', marginLeft: 12}}>
									      	<strong style={{fontSize: 16}}>{discussion.title}</strong> 
									        </p>
									        </Row>
									  	  }
									      content={
									        <p style={{color: 'white'}} >
									         <p onClick={() => selectDiscussion(discussion)}>{discussion.content}</p> { permissions.admin ? <DeleteOutlined onClick={() => removeDiscussion(discussion)} style={{float: 'right', marginRight: 12}}/> : null }
									        </p>
									      }
									      key={idx}
									
									    />
							})
						}
				    </Panel>
				    <Panel header={<p><strong>Chat</strong><Tooltip placement="right" title={'ⓘ Currently, our chat function is being handled through Discord. This is a live feed pulled from our Discord Server. You will be notified once we launch our own messenger function.'}><InfoCircleOutlined style={{marginLeft: 4}}/></Tooltip><a href="https://discord.gg/8pykF6ry" style={{float: 'right'}}> Go to Discord ></a></p>} key="3">
				      {
							conversations.slice(0,10).map(function(message, idx){
								return <Comment
										  style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5}}
									      author={
									      	<Row>
									      	<Avatar
									          size={40}
									          src={message.avatar_url}
									          alt={message.username}
									        />
									      	<p style={{color: 'white', marginLeft: 12}}>
									      	<strong style={{fontSize: 16}}>{message.username}</strong> 
									      		<br/>{message.channel}
									        </p>
									        </Row>
									  	  }
									      content={
									        <p style={{color: 'white'}}>
									          {message.content}
									        </p>
									      }
									      key={idx}
									    />
							})
						}
				    </Panel>
			    </Collapse>
			</div>
		</div>
	)
}

export default ConversationsTab;