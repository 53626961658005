import { QuestionOutlined } from '@ant-design/icons';
import React from "react";
import { Button, Tooltip } from 'antd';

const Question = () => {

	const text = <p style={{maxHeight: 250, overflowY: 'scroll'}}> 
		Pins on this map correspond to the Standard Menu, and the “+” symbol in the bottom right-hand corner of the map. <br/><br/>
		The “+” icon allows you to post quickly post content, and interact with the map - but you can do this within the Standard Menu as well. <br/><br/>
		Pins are color-coded. <br/><br/>
		Red pins are content posted by NOMAD only (i.e. Guides, or NOMAD locations).<br/>
		White pins are either *Personal Posts* (only visible to you), or other users’ locations (linking to their Profiles).<br/>
		Blue pins are *Public Posts* - these are content pins created for the community, by the community. <br/>
		Green pins are *Locations* - Member Hosted, or Partner Hosted. <br/>
		Color-Filled Countries are either on your “Visited” or “Bucket List” lists.<br/>
		Dashed-Lines represent a “My Trips” leg - (i.e. Flight from NYC to CDG) <br/>
	</p>

	return (
		<React.Fragment>
			<Tooltip placement="left" title={text}>
			<Button 
				type="primary" 
				shape="circle" 
				icon={<QuestionOutlined />} 
				size='small'
				id='add-button'
				style={{
					position: 'fixed', right: '24px', 
					bottom: '84px', zIndex: 1000, boxShadow: '0px 0px 15px black'}}
			/>
			</Tooltip>
		</React.Fragment>
	)
}

export default Question;