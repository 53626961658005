import React from 'react';
import { Button, Typography } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from '@ant-design/icons';
import { setMapGrid } from "../../../actions/mapgrid";
import Comments from "../comments";
const { Title } = Typography;

const Discussion = () => {

	const selectedDiscussion = useSelector(state => state.discussions.selectedDiscussion);
	const dispatch = useDispatch();
	const mapgrid = useSelector(state => state.mapGrid.mapgrid);
	return (
		<React.Fragment>
			<div style={{ textAlign: 'left', position: 'relative', width: '100%', overflowY: 'scroll', padding: '100px', paddingTop:'100px', display: (mapgrid === 'discussion' ? null : 'none')}}>
			<Button 
				style={{float: 'right', zIndex: 10}}
				size='large'
				icon={<CloseOutlined/>}
				onClick={() => {dispatch(setMapGrid('map'))}}
			/>
			<Title> {selectedDiscussion ? selectedDiscussion.title : null} </Title>
			<Title level={4}> {selectedDiscussion ? selectedDiscussion.content : null} </Title>
			{selectedDiscussion ? <Comments parentId={selectedDiscussion.discussion_id}/> : null}
			</div>
		</React.Fragment>
	)
}

export default Discussion;