import axios from 'axios';

import { 
	ADD_REVIEWS
} from '../constants/action-types'

import {
	API_URL
} from '../constants/config'

// Conversations
export function addReviews(reviews) {
	return {
		type: ADD_REVIEWS,
		reviews: reviews
	};
}

export function getReviews(token, location_id) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/reviews`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	target_id: location_id
		  }
		}).then(data => {
			dispatch(addReviews(data.data.reviews))
        })
	}
}

export function createReview(review, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/reviews`, review, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}