import {
    SET_MAP_GRID
} from '../constants/action-types'

export function setMapGrid(mapgrid) {
	return {
		type: SET_MAP_GRID,
		mapgrid: mapgrid
	};
}
