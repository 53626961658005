import {
    SET_SELECTED_PROFILE, SET_MY_PROFILE, SET_PROFILES
} from '../constants/action-types'

let initialState = {
    selectedProfile: null,
    profiles: [],
    myProfile: null
};

export default function profile(state=initialState, action) {
    switch (action.type) {
        case SET_SELECTED_PROFILE: {
            return Object.assign({}, state, {
                selectedProfile: action.selectedProfile
            });
        }
        case SET_PROFILES: {
            return Object.assign({}, state, {
                profiles: action.profiles
            });
        }
        case SET_MY_PROFILE: {
            return Object.assign({}, state, {
                myProfile: action.myProfile
            });
        }
        default:
            return state
    }
}
