import {
    SET_TRIPS, SET_SELECTED_TRIP
} from '../constants/action-types'

let initialState = {
    trips: [],
    selectedTrip: {}
};

export default function trips(state=initialState, action) {
    switch (action.type) {
        case SET_TRIPS: {
            return Object.assign({}, state, {
                trips: action.trips
            });
        }
        case SET_SELECTED_TRIP: {
            return Object.assign({}, state, {
                selectedTrip: action.selectedTrip
            });
        }
        default:
            return state
    }
}
