import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Empty, Typography, Button, Row, Col, Tooltip } from 'antd';
import { HomeOutlined, RightOutlined, DownOutlined, PlusOutlined, CarOutlined, BranchesOutlined, TrademarkOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { setSelectedTrip } from "../../../actions/trips";
import { getTravels } from '../../../actions/travels';
import { getStays } from '../../../actions/stays';
import { setModal } from '../../../actions/modals';
import TravelModal from '../../home/map/modals/travel';
import StayModal from '../../home/map/modals/stay';
const { Title } = Typography;

const TripsTab = () => {
	const trips = useSelector(state => state.trips.trips);
	const map = useSelector(state => state.map.map);
	const selectedTrip = useSelector(state => state.trips.selectedTrip);
	const token = useSelector(state => state.token.token);
	const [selectedTravels, setSelectedTravels] = useState([]);
	const [selectedStays, setSelectedStays] = useState([]);
	const [futureTravels, setFutureTravels] = useState([]);
	const [futureStays, setFutureStays] = useState([]);
	const sidebarTab = useSelector(state => state.sidebar.sidebarTab);
	const dispatch = useDispatch();

	const fetchTravels = function(trip, future) {
		dispatch(getTravels(trip.trip_id, token)).then(function(travels){
			if(!future){
				setSelectedTravels(travels.data.travels);
			}
			else {
				setFutureTravels(futureTravels.concat(travels.data.travels));
			}
		});
	}

	const fetchStays = function(trip, future) {
		dispatch(getStays(trip.trip_id, token)).then(function(stays){
			if(!future){
				setSelectedStays(stays.data.stays);
			}
			else {
				setFutureStays(futureStays.concat(stays.data.stays));
			}
		});
	}

	const selectTrip = (trip) => {
		fetchTravels(trip);
		fetchStays(trip);
		dispatch(setSelectedTrip(trip));
	}

	const newTravel = (trip) => {
		dispatch(setModal('travel'));
	}

	const newStay = (trip) => {
		dispatch(setModal('stay'));
	}

	const onNewTravel = (travel) => {
		selectTrip(selectedTrip);
	}

	const onNewStay = (stay) => {
		selectTrip(selectedTrip);
	}

	const bezier = (startCoord, endCoord, inverted) => {
		const midpoint = [
			(startCoord[0] + endCoord[0]) / 2,
			(startCoord[1] + endCoord[1]) / 2
		]
		const slope = (endCoord[1] - startCoord[1]) / (endCoord[0] - startCoord[0])
		const length = ((endCoord[0]-startCoord[0])**2 + (endCoord[1]-startCoord[1])**2)**(0.5)
		const orthogonalSlope = 1.0 / slope;
		const b = midpoint[1] - orthogonalSlope * midpoint[0] 
		const orthogonalLine = (x) => orthogonalSlope * x + b
		const distExt = (length > 50 && length < 150) ? 2 : 1
		const distFact = 2.0 * distExt
		const invertedFact = inverted ? -1 : 1;
		const othogonalPoint = [midpoint[0] + distFact * invertedFact, orthogonalLine(midpoint[0] + distFact * invertedFact)]
		const bz = function(t) {
			const a = (1-t)*(1-t);
			const b = 2*(1-t)*t;
			const c = t*t;
			const p0 = [a*startCoord[0], a*startCoord[1]]
			const p1 = [b*othogonalPoint[0], b*othogonalPoint[1]]
			const p2 = [c*endCoord[0], c*endCoord[1]]
			return [ p0[0] + p1[0] + p2[0], p0[1] + p1[1] + p2[1]]
	 	}
	 	const interpRate = 20
		const interpPoints = [...Array(interpRate+1).keys()].map(function(p){
			return p / interpRate;
		})
		const curvePoints = interpPoints.map(function(iP){
			return bz(iP);
		});
		return curvePoints;
	}

	useEffect(() => {
		const upcomingTrips = trips.filter(function(trip){
			return Date.parse(trip.startDate) > Date.now();
		});
		upcomingTrips.forEach(function(trip){
			fetchTravels(trip, true);
		});	
	// eslint-disable-next-line
	}, [trips])

	useEffect(() => {
		if(sidebarTab !== 'trips'){
			map.getSource('travelLine').setData({
				"type": "FeatureCollection",
				"features": []
			})
			map.getSource('travelPoint').setData({
				"type": "FeatureCollection",
				"features": []
			})
			return
		} 
		const cleanSelectedTravels = futureTravels.concat(selectedTravels).map(function(travel){
			const newTravel = {}
			newTravel.startCoords = [parseFloat(travel.startCoords[1]), parseFloat(travel.startCoords[0])];
			newTravel.endCoords = [parseFloat(travel.endCoords[1]), parseFloat(travel.endCoords[0])];
			newTravel.properties = {
				...travel
			}
			return newTravel;
		});

		const travelLineFeatures = cleanSelectedTravels.map(function(travel, index){
			const coords = bezier(travel.startCoords, travel.endCoords, index%2)
			return {
				"type": "Feature",
				"properties": {
					index: index%5,
					...travel.properties
				},
				"geometry": {
					"type": "LineString",
					"coordinates": coords
				}
			}
		});
		const travelPointFeatures = [];

		cleanSelectedTravels.forEach(function(travel, index){
			travelPointFeatures.push({
				"type": "Feature",
				"properties": {
					address: travel.properties.startAddress
				},
				"geometry": {
					"type": "Point",
					"coordinates": travel.startCoords
				}
			});
			travelPointFeatures.push({
				"type": "Feature",
				"properties": {
					address: travel.properties.endAddress
				},
				"geometry": {
					"type": "Point",
					"coordinates": travel.endCoords
				}
			})
		});

		const travelLineFc = {
			"type": "FeatureCollection",
			"features": travelLineFeatures
		}
		const travelPointFc = {
			"type": "FeatureCollection",
			"features": travelPointFeatures
		}

		if(!map.getSource('travelLine')){
			map.addSource('travelLine', {
				'type': 'geojson',
				'data': travelLineFc
			});
		} else {
			map.getSource('travelLine').setData(travelLineFc)
		}

		if(!map.getSource('travelPoint')){
			map.addSource('travelPoint', {
				'type': 'geojson',
				'data': travelPointFc
			});
		} else {
			map.getSource('travelPoint').setData(travelPointFc)
		}

		if(!map.getLayer('travelLine')){
			map.addLayer({
				'id': 'travelLine',
				'type': 'line',
				'source': 'travelLine',
				'layout': {
					'line-join': 'round',
					'line-cap': 'round'
				},
				'paint': {
					'line-color': [
						'match',
						['get', 'index'],
						0,
						'#FF0000',
						1,
						'#8AEB3F',
						2,
						'#29A8EF',
						3,
						'#DD21BF',
						4,
						'#E9D04B',
						/* other */ '#ccc'
					],
					'line-width': 2,
					'line-dasharray': [2, 2]
				}
			});
		}

		if(!map.getLayer('travelPoint')){
			map.addLayer({
				'id': 'travelPoint',
				'type': 'circle',
				'source': 'travelPoint',
				'paint': {
					"circle-radius": 3,
		            "circle-color": "#FFFFFF",
		            "circle-opacity": 1.0,
		            "circle-stroke-width": 0
				}
			});
		}
	}, [selectedTravels, futureTravels, sidebarTab, map])

	return(
		<div className="tab-container">
			<div className='tab-content'>
			<Row>
				<Title level={2} style={{color: 'black', fontWeight: 'bold', fontSize: '30px', letterSpacing: '2px'}}>My Trips</Title> 
				<Tooltip placement="right" title={'ⓘ Post your upcoming trips, both for your own sake, and for your followers & friends! Once you post a trip (give it a title), you can set dates & locations for each leg of the trip (i.e. JFK - CDG), and these will show up on your personal map and your public profile as colored dashed-lines. '}><InfoCircleOutlined style={{fontSize: 12, marginLeft: 8, paddingTop: 16}}/></Tooltip>
			</Row>
			{
				(trips && trips.length === 0) ? <Empty
				    image="./nomad-logo-square-black.png"
				    imageStyle={{
				      height: 60,
				    }}
				    description={
				      <span>
				        No Trips 
				      </span>
				    }
				  >
				    <Button onClick={() => dispatch(setModal('trip'))} type="primary">Add A Trip</Button>
				  </Empty> : null 
			}
			<Title level={4} style={{color: 'black', fontWeight: 'bold', letterSpacing: '2px'}}>Upcoming Trips</Title> 
			{ 
				trips ? trips.filter(function(trip){
					return Date.parse(trip.startDate) > Date.now();
				}).map(function(trip, idx){
					return	<React.Fragment key={idx} >
								<div className="trip-card" onClick={() => {
										(selectedTrip.trip_id === trip.trip_id) ? (function(){
											dispatch(setSelectedTrip({}));
											setSelectedTravels([]);
										})() : selectTrip(trip)
									}
								}>
									<p style={{margin: 0}}>
										<strong>{trip.name}</strong> 
										<span style={{float:'right'}}>
											{selectedTrip.trip_id === trip.trip_id ? <DownOutlined /> : <RightOutlined />} 
										</span>
									</p>
									<p>{trip.destinations}</p>
									<Row>
										<Col span={10}>
											{trip.startDate.slice(0,10)}
										</Col>
										<Col span={4}>
											-
										</Col>
										<Col span={10}>
											{trip.endDate.slice(0,10)}
										</Col>
									</Row>
								</div>
								{selectedTrip.trip_id === trip.trip_id ? <div>
									{selectedTravels ? selectedTravels.map(function(travel, idx){
										return <div className="travel-card" key={idx} > 
											<Row>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{travel.startDate.substring(0,10)} </strong></p>
												<span style={{fontSize: 10}}>{travel.startAddress.substring(0, 20)}</span>
												</Col>
												<Col span={2}>
												<p style={{marginBottom: 0}}> <strong> - </strong></p>
												</Col>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{travel.endDate.substring(0,10)} </strong></p>
												<span style={{fontSize: 10}}>{travel.endAddress.substring(0, 20)}</span>
												</Col>
												<Col span={2} style={{color: 'white'}}>
													{travel.mode === 'Flight' ? <img alt="plane" style={{color: 'white'}} src="./plane-white.svg"/> : null}
													{travel.mode === 'Car' ? <CarOutlined /> : null}
													{travel.mode === 'Bus' ? <BranchesOutlined /> : null}
													{travel.mode === 'Train' ? <TrademarkOutlined /> : null}

												</Col>
											</Row>
										</div>
									}) : null}
									{selectedStays ? selectedStays.map(function(stay, idx){
										return <div className="travel-card" key={idx} > 
											<Row>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{stay.startDate.substring(0,10)} </strong></p>
												<span style={{fontSize: 10}}>{stay.address.substring(0, 20)}</span>
												</Col>
												<Col span={2}>
												<p style={{marginBottom: 0}}> <strong> - </strong></p>
												</Col>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{stay.endDate.substring(0,10)} </strong></p>
												</Col>
											</Row>
										</div>
									}) : null}
									<Row>
										<Col span={10}>
											<Button onClick={() => newTravel(trip)} type="primary" icon={<PlusOutlined />} style={{marginTop: 8, width: '100%'}}>
										      Add travel
										    </Button>
									    </Col>
									    <Col span={4}></Col>
									    <Col span={10}>
										    <Button onClick={() => newStay(trip)} type="primary" icon={<PlusOutlined />} style={{marginTop: 8, width: '100%'}}>
										      Add stay
										    </Button>
									    </Col>
									    <Col span={2} style={{color: 'white'}}>
											<HomeOutlined />
										</Col>
									</Row>
								</div> : null }
							</React.Fragment>
				}) : null
			} 

			<Title level={4} style={{color: 'black', fontWeight: 'bold', letterSpacing: '2px'}}>Past Trips</Title> 
			{ 
				trips ? trips.filter(function(trip){
					return Date.parse(trip.startDate) < Date.now();
				}).map(function(trip, idx){
					return	<React.Fragment key={idx} >
								<div className="trip-card" onClick={() => {
										(selectedTrip.trip_id === trip.trip_id) ? (function(){
											dispatch(setSelectedTrip({}));
											setSelectedTravels([]);
										})() : selectTrip(trip)
									}
								}>
									<p style={{margin: 0}}>
										<strong>{trip.name}</strong> 
										<span style={{float:'right'}}>
											{selectedTrip.trip_id === trip.trip_id ? <DownOutlined /> : <RightOutlined />} 
										</span>
									</p>
									<p>{trip.destinations}</p>
									<Row>
										<Col span={10}>
											{trip.startDate.slice(0,10)}
										</Col>
										<Col span={4}>
											-
										</Col>
										<Col span={10}>
											{trip.endDate.slice(0,10)}
										</Col>
									</Row>
								</div>
								{selectedTrip.trip_id === trip.trip_id ? <div>
									{selectedTravels ? selectedTravels.map(function(travel, idx){
										return <div className="travel-card" key={idx} > 
											<Row>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{travel.startDate.substring(0,10)} </strong></p>
												<span style={{fontSize: 10}}>{travel.startAddress.substring(0, 20)}</span>
												</Col>
												<Col span={2}>
												<p style={{marginBottom: 0}}> <strong> - </strong></p>
												</Col>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{travel.endDate.substring(0,10)} </strong></p>
												<span style={{fontSize: 10}}>{travel.endAddress.substring(0, 20)}</span>
												</Col>
												<Col span={2} style={{color: 'white'}}>
													{travel.mode === 'Flight' ? <img alt="plane" style={{color: 'white'}} src="./plane-white.svg"/> : null}
													{travel.mode === 'Car' ? <CarOutlined /> : null}
													{travel.mode === 'Bus' ? <BranchesOutlined /> : null}
													{travel.mode === 'Train' ? <TrademarkOutlined /> : null}

												</Col>
											</Row>
										</div>
									}) : null}
									{selectedStays ? selectedStays.map(function(stay, idx){
										return <div className="travel-card" key={idx} > 
											<Row>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{stay.startDate.substring(0,10)} </strong></p>
												<span style={{fontSize: 10}}>{stay.address.substring(0, 20)}</span>
												</Col>
												<Col span={2}>
												<p style={{marginBottom: 0}}> <strong> - </strong></p>
												</Col>
												<Col span={10}>
												<p style={{marginBottom: 0}}><strong>{stay.endDate.substring(0,10)} </strong></p>
												</Col>
												<Col span={2} style={{color: 'white'}}>
													<HomeOutlined />
												</Col>
											</Row>
										</div>
									}) : null}
									<Row>
										<Col span={10}>
											<Button onClick={() => newTravel(trip)} type="primary" icon={<PlusOutlined />} style={{marginTop: 8, width: '100%'}}>
										      Add travel
										    </Button>
									    </Col>
									    <Col span={4}></Col>
									    <Col span={10}>
										    <Button onClick={() => newStay(trip)} type="primary" icon={<PlusOutlined />} style={{marginTop: 8, width: '100%'}}>
										      Add stay
										    </Button>
									    </Col>
									</Row>
								</div> : null }
							</React.Fragment>
				}) : null
			} 
			</div>
			<TravelModal onNewTravel={onNewTravel} selectedTrip={selectedTrip}/>
			<StayModal onNewStay={onNewStay} selectedTrip={selectedTrip}/>
		</div>
	)
}

export default TripsTab;