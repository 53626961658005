import {
    ADD_POINTS
} from '../constants/action-types'

let initialState = {
    points: null
};

export default function points(state=initialState, action) {
    switch (action.type) {
        case ADD_POINTS: {
            return Object.assign({}, state, {
                points: parseInt(action.points)
            });
        }
        default:
            return state
    }
}
