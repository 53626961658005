import React from "react";
import { PlusOutlined, CheckOutlined} from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'antd';
import { createFriend, getFriends } from "../../../actions/friends";
import { useAuth0 } from "@auth0/auth0-react";

const AddFriendButton = (props) => {

	const friendIds = useSelector(state => state.friends.friendIds);
	const friends = useSelector(state => state.friends.friends);
	const token = useSelector(state => state.token.token);
	const dispatch = useDispatch();

	const {
		user
	} = useAuth0();

	const friendToggle = () => {
		if(friendIds.indexOf(props.friend.sub) > - 1) {
			// Remove Friend
			const friend_id = friends.filter(function(friend){
				return (friend.sub === user.sub) && (friend.friend === props.friend.sub)
			})[0].friend_id;
			console.log(friend_id)
			dispatch(createFriend({
				friend_id: friend_id,
				remove: true
			}, token)).then(function(){
				dispatch(getFriends(token))
				props.onFriendChange(token);
			});
		} else {
			// Add Friend
			dispatch(createFriend(props.friend, token)).then(function(){
				dispatch(getFriends(token))
				props.onFriendChange(token);
			});
		}
	};

	return (
		<Button size={'small'} style={{fontSize: 10}} type="primary" icon={(friendIds && props.friend) ? (friendIds.indexOf(props.friend.sub) > - 1 ?  <CheckOutlined/> : <PlusOutlined/> ) : null} onClick={() => friendToggle()}>
	      {( props.friend && friendIds.indexOf(props.friend.sub) > - 1) ? 'Unfollow' : 'Follow'}
	    </Button>
	)
}

export default AddFriendButton;