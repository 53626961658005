import {
    ADD_GUIDES
} from '../constants/action-types'

let initialState = {guides: null};

export default function guides(state=initialState, action) {
    switch (action.type) {
        case ADD_GUIDES: {
            return Object.assign({}, state, {
                guides: action.guides
            });
        }
        default:
            return state
    }
}
