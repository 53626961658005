import React from 'react';
import { Row, Col, Button, Image, Typography } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from '@ant-design/icons';
import { setMapGrid } from "../../../actions/mapgrid";
import Comments from "../comments";
const { Title, Paragraph } = Typography;

const Post = () => {

	const selectedPost = useSelector(state => state.posts.selectedPost);
	const dispatch = useDispatch();
	const mapgrid = useSelector(state => state.mapGrid.mapgrid);

	return (
		<React.Fragment>
			<div className="sidebar-offset" style={{ textAlign: 'left', width: '100%', overflowY: 'scroll', display: (mapgrid === 'post' ? null : 'none')}}>
			<Button 
				style={{float: 'right', zIndex: 10}}
				size='large'
				icon={<CloseOutlined/>}
				onClick={() => {dispatch(setMapGrid('map'))}}
			/>
			{ selectedPost ? <Title level={4} style={{textAlign: 'left'}}>{selectedPost.properties.title.toUpperCase()}</Title> : null }
			{ selectedPost ? <Row gutter={[16, 16]} style={{width: '100%'}}>
				<Col span={16}>
					<Image style={{maxHeight: '63vh', overflow: 'hidden'}} src={selectedPost.properties.images[0]}/>
				</Col>
				<Col span={8} gutter={[16, 16]}>
					<Image style={{maxHeight: '30vh', overflow: 'hidden'}}src={selectedPost.properties.images[1]}/>
					<Image style={{marginTop: 12, maxHeight: '30vh', overflow: 'hidden'}} src={selectedPost.properties.images[2]}/>
				</Col>
				
			</Row> : null }
			<div style={{paddingRight: '15%'}}>
			{ selectedPost ? 
				<Typography style={{textAlign: 'left'}}>
					<Title level={4}> 
						{selectedPost.properties.address}
					</Title>
					<p>Posted by {selectedPost.properties.name}</p>
					<Paragraph>
				      {selectedPost.properties.content}
				    </Paragraph>
				</Typography> : null
			} 

			{ selectedPost ? <Comments parentId={selectedPost.properties.post_id}/> : null }
      		</div>
			</div>
		</React.Fragment>
	)
}

export default Post;