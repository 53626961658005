import React, {  useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useSelector, useDispatch } from "react-redux";
import { lowerResizeFlag, setMap, setGeocoder, setFeaturesInView } from "../../../actions/map";
import { setSelectedLocation } from "../../../actions/locations";
import { setSelectedProfile } from "../../../actions/profile";
import { setSelectedPost } from "../../../actions/posts";
import { setSidebarStatus } from "../../../actions/sidebar";
import { ACCESS_TOKEN, GEOCODING_URL } from '../../../constants/constants';
import { setMapGrid } from "../../../actions/mapgrid"
import './map.css'
import PostPopUp from "./popups/post"
import LocationPopUp from "./popups/location"
import UserLocationPopUp from "./popups/userLocation"
import TripLocationPopUp from "./popups/tripLocation"
import Add from './add.js'
import Question from './question.js'
import Trigger from './trigger.js'
import Notifications from './notifications.js'
import Legend from './legend.js'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = ACCESS_TOKEN;

const Map = () => {
	const mapContainer = useRef(null);
	const dispatch = useDispatch();
	const locations = useSelector(state => state.locations.locations);
	const guides = useSelector(state => state.guides.guides);
	const userLocations = useSelector(state => state.userLocations.userLocations);
	const profiles = useSelector(state => state.profile.profiles);
	const posts = useSelector(state => state.posts.posts);
	const resizeFlag = useSelector(state => state.map.resizeFlag);
	const map = useSelector(state => state.map.map);
	const geocoder = useSelector(state => state.map.geocoder);
	const mapgrid = useSelector(state => state.mapGrid.mapgrid);
	let featuresViewTimeout = null;

	useEffect(() => {
		if(!map){
			return;
		}
		dispatch(lowerResizeFlag());
		setTimeout(function(){
			map.resize();
		}, 200)
	}, [resizeFlag, dispatch, map]);

	useEffect(() => {
		if(!map){
			return;
		}
		map.resize();
	}, [mapgrid, map]);

	// Map Initialization 
	useEffect(() => {
		const m = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/dark-v9',
			center: [5, 34],
			zoom: 1,
			renderWorldCopies: true,
			maxBounds: [
				[-720, -60], // Southwest coordinates
				[720, 80] // Northeast coordinates
			]
		});

		// disable map rotation using right click + drag
		m.dragRotate.disable();
		 
		// disable map rotation using touch rotation gesture
		m.touchZoomRotate.disableRotation();

		const gc = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl
		});

		m.addControl(
			gc
		);

		m.on('zoom', function(){
			clearTimeout(featuresViewTimeout);
			// eslint-disable-next-line
			featuresViewTimeout = setTimeout(function(){
				var features = m.queryRenderedFeatures({ 
					layers: ['places', 'guides'] 
				});
				dispatch(setFeaturesInView(features));
			}, 500);
		})

		function onStyleLoad(e) {
	      m.addSource('img_overlay', {
	        type: "image",
	        url: './GridGrad2.jpg',
	        coordinates: [[-180,80],[180,80],[180,-70],[-180,-70]]
	      });

	      m.addLayer({
	          id: 'img_overlay',
	          source: 'img_overlay',
	          type: "raster",
	          paint: {"raster-opacity": 0.075}
	      });
	    }

	    m.on('style.load', onStyleLoad);

		dispatch(setGeocoder(gc));
		dispatch(setMap(m));
	}, [dispatch]);

	useEffect(() => {
		if(map && map.getSource('places')){
			map.getSource('places').setData(locations);
		}
	}, [map, locations])

	useEffect(() => {
		if(map && map.getSource('guides')){
			map.getSource('guides').setData(guides);
		}
	}, [map, guides])

	useEffect(() => {
		if(map && map.getSource('posts')){
			const publicPosts = {
				type: "FeatureCollection",
				features: posts.features.filter(function(post){
					return post.properties.public
				})
			};
			map.getSource('posts').setData(publicPosts);
		}
	}, [map, posts])

	useEffect(() => {
		if(map && map.getSource('private')){
			const privatePosts = {
				type: "FeatureCollection",
				features: posts.features.filter(function(post){
					return !post.properties.public
				})
			};
			map.getSource('private').setData(privatePosts);
		}
	}, [map, posts])

	useEffect (() => {
		if(!map || !userLocations){
			return;
		}

		map.loadImage(
		'./user-pin.png', 
		function(error, image){
			if(!map.hasImage('user-marker')){
				map.addImage('user-marker', image);
			}

			if(map.getSource('userLocations')){
				return;
			} else {
				map.addSource('userLocations', {
					'type': 'geojson',
					'data': userLocations
				});

				map.addLayer({
					'id': 'userLocations',
					'type': 'symbol',
					'source': 'userLocations',
					'layout': {
						'icon-image': 'user-marker',
						'icon-size': .02,
					}
				});
			}
		});
	}, [map, userLocations])

	useEffect (() => {
		if(!map || !posts){
			return;
		}

		map.loadImage(
		'./post.png', 
		function(error, image){
			if(!map.hasImage('post-marker')){
				map.addImage('post-marker', image);
			}
			
			const publicPosts = {
				type: "FeatureCollection",
				features: posts.features.filter(function(post){
					return post.properties.public
				})
			};

			if(map.getSource('posts')){
				return;
			} else {
				map.addSource('posts', {
					'type': 'geojson',
					'data': publicPosts
				});

				map.addLayer({
					'id': 'posts',
					'type': 'symbol',
					'source': 'posts',
					'layout': {
						'icon-image': 'post-marker',
						'icon-size': 0.02,
					}
				});
			}
		});
	}, [map, posts])

	useEffect (() => {
		if(!map || !posts){
			return;
		}

		map.loadImage(
		'./private-pin.png', 
		function(error, image){
			if(!map.hasImage('private-marker')){
				map.addImage('private-marker', image);
			}
			
			const privatePosts = {
				type: "FeatureCollection",
				features: posts.features.filter(function(post){
					return !post.properties.public
				})
			};

			if(map.getSource('private')){
				return;
			} else {
				map.addSource('private', {
					'type': 'geojson',
					'data': privatePosts
				});

				map.addLayer({
					'id': 'private',
					'type': 'symbol',
					'source': 'private',
					'layout': {
						'icon-image': 'private-marker',
						'icon-size': 0.028,
					}
				});
			}
		});
	}, [map, posts])

	useEffect (() => {
		if(!map || !locations){
			return;
		}

		map.loadImage(
		'./location-pin.png', 
		function(error, image){
			if(!map.hasImage('location-marker')){
				map.addImage('location-marker', image);
			}
			
			if(map.getSource('places')){
				return;
			} else {
				map.addSource('places', {
					'type': 'geojson',
					'data': locations
				});

				map.addLayer({
					'id': 'places',
					'type': 'symbol',
					'source': 'places',
					'layout': {
						'icon-image': 'location-marker',
						'icon-size': 0.02,
						// get the title name from the source's "title" property
						'text-field': ['get', 'title'],
						'text-font': [
							'Open Sans Semibold',
							'Arial Unicode MS Bold'
						],
						'text-offset': [0, 1.25],
						'text-anchor': 'top'
					}
				});
			}
		});
	}, [map, locations])

	useEffect(() => {
		if(!map || !guides){
			return;
		}

		map.loadImage(
		'./guide-pin.png', 
		function(error, image){
			if(!map.hasImage('guide-marker')){
				map.addImage('guide-marker', image);
			}

			if(!map.getSource('travelLine')){
				map.addSource('travelLine', {
					'type': 'geojson',
					'data': {
						"type": "FeatureCollection",
						"features": []
					}
				});
			} 

			if(!map.getLayer('travelLine')){
				map.addLayer({
					'id': 'travelLine',
					'type': 'line',
					'source': 'travelLine',
					'layout': {
						'line-join': 'round',
						'line-cap': 'round'
					},
					'paint': {
						'line-color': [
							'match',
							['get', 'index'],
							0,
							'#FF0000',
							1,
							'#8AEB3F',
							2,
							'#29A8EF',
							3,
							'#DD21BF',
							4,
							'#E9D04B',
							/* other */ '#ccc'
						],
						'line-width': 2,
						'line-dasharray': [2, 2]
					}
				});
			}

			if(map.getSource('guides')){
				return;
			} else {
				map.addSource('guides', {
					'type': 'geojson',
					'data': guides
				});

				map.addLayer({
					'id': 'guides',
					'type': 'symbol',
					'source': 'guides',
					'layout': {
						'icon-image': 'guide-marker',
						'icon-size': 0.02,
						// get the title name from the source's "title" property
						'text-field': ['get', 'title'],
						'text-font': [
							'Open Sans Semibold',
							'Arial Unicode MS Bold'
						],
						'text-offset': [0, 1.25],
						'text-anchor': 'top'
					}
				});
			}
		})
	}, [map, guides]);

	useEffect (() => {
		if(!map || !profiles || !profiles.length){
			return;
		}
		const onclick = (e) => {
			var bbox = [
                [e.point.x - 30, e.point.y - 30],
                [e.point.x + 30, e.point.y + 30]
            ];
			const features = e.target.queryRenderedFeatures(bbox, {
                layers: ['places', 'guides', 'posts', 'private', 'userLocations', 'travelLine']
            });
           	if(!features.length){
           		if(map.getZoom() > 3){
           			return;
           		}
           		axios.get(GEOCODING_URL + e.lngLat.lng + ',' + e.lngLat.lat + '.json?access_token=' + mapboxgl.accessToken).then(
           			function(e){
	           			const country = e.data.features.filter(function(f){
	           				return f.place_type[0] === 'country'
	           			})[0];
	           			if(country && country.text){
	           				geocoder.query(country.text)
	           				geocoder._clear()
	           			}
           			});
           	} else if (features[0].source === 'userLocations') {
           		const placeholder = document.createElement('div');
           		const popup = new mapboxgl.Popup();
           		const selectProfile = profiles.filter(function(profile){
           			return features[0].properties.sub === profile.sub;
           		})[0];
           		const handler = () => {
           			dispatch(setSidebarStatus(true));
           			dispatch(setSelectedProfile(selectProfile));
           			dispatch(setMapGrid('profile'));
				}
       			const elem = <UserLocationPopUp handler={handler} selectProfile={selectProfile}/>
    			ReactDOM.render(elem, placeholder);
    			popup.setLngLat(features[0].geometry.coordinates).setDOMContent(placeholder).addTo(map); 
           	} else if (features[0].source === 'travelLine') {
           		const placeholder = document.createElement('div');
           		const popup = new mapboxgl.Popup();
       			const elem = <TripLocationPopUp 
	       				startAddress={features[0].properties.startAddress} 
	       				endAddress={features[0].properties.endAddress}
	       				startDate={features[0].properties.startDate}
	       				endDate={features[0].properties.endDate}
       				/>
    			ReactDOM.render(elem, placeholder);
    			popup.setLngLat([e.lngLat.lng, e.lngLat.lat]).setDOMContent(placeholder).addTo(map); 
           	} else if (features[0].source === 'guides') {
       			const image_url = features[0].properties.image
       			const pdf_url = features[0].properties.pdf
       			new mapboxgl.Popup()
       					.setLngLat(features[0].geometry.coordinates)
       					.setHTML('<a rel="noreferrer" target="_blank" download href="' + pdf_url +'"><Image style="width:100px" src="' + image_url + '"/></a>')
       					.addTo(map);
           	} else if (features[0].source === 'posts' || features[0].source === 'private') {
           		const placeholder = document.createElement('div');
           		const popup = new mapboxgl.Popup();
           		const handler = () => {
					if (typeof features[0].properties.images === 'string'){
           				features[0].properties.images = features[0].properties.images.replace('[', '').replace(']', '').replaceAll('"','').split(',');
           			}
           			dispatch(setSelectedPost(features[0]));
					dispatch(setMapGrid('post'));
					dispatch(setSidebarStatus(true));
				}
           		const elem = <PostPopUp handler={handler} feature={features[0]}/>
       			ReactDOM.render(elem, placeholder);
           		popup.setLngLat(features[0].geometry.coordinates).setDOMContent(placeholder).addTo(map); 
           	} else {
           		const placeholder = document.createElement('div');
           		const handler = () => {
           			if (typeof features[0].properties.images === 'string'){
           				features[0].properties.images = features[0].properties.images.replace('[', '').replace(']', '').replaceAll('"','').split(',');
           			}
           			dispatch(setSelectedLocation(features[0]));
					dispatch(setMapGrid('location'));
					dispatch(setSidebarStatus(true));
           		}
           		const elem = <LocationPopUp handler={handler} feature={features[0]}/>
    			ReactDOM.render(elem, placeholder);
           		new mapboxgl.Popup()
       					.setLngLat(features[0].geometry.coordinates)
       					.setDOMContent(placeholder)
       					.addTo(map); 
           		//mapOnClick(e, features, {dispatch, setSidebarStatus, setSidebarTab, setSelectedLocation});
           	}
		};

		map.on('click', onclick);
	// eslint-disable-next-line
	}, [map, profiles]);

	return (
		<React.Fragment>
			<Add/>
			<Trigger/>
			<Question/>
			<Notifications/>
			<Legend/>
			<div style={{width: '100%', display: (mapgrid === 'map' ? null : 'none')}}  ref={mapContainer}>
			</div>
		</React.Fragment>
	)
}

export default Map;