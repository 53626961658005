import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useSelector, useDispatch } from "react-redux";
import { createUserLocation } from '../../../actions/userLocations'

Geocode.setApiKey("AIzaSyBz1MWstxibB3Nwb8ulAPLDoFwUSz1iFHM");
Geocode.setLanguage("en");
Geocode.setRegion("US");
Geocode.setLocationType("ROOFTOP");

const UserLocation = () => {

	const [city, setCity] = useState('Unknown');
	const [state, setState] = useState('');
	const [coord, setCoord] = useState([])
	const dispatch = useDispatch();
	const map = useSelector(state => state.map.map);
	const token = useSelector(state => state.token.token);

	const goToCity = () => {
		map.flyTo({
			center: coord,
			zoom: 20
		});
	}

	useEffect(() => {
		if(!token || !coord || !coord.length) {
			return;
		}
		dispatch(createUserLocation({
			coordinates: coord
		}, token))
	// eslint-disable-next-line
	}, [token, coord])

	useEffect(() => {
		if ("geolocation" in navigator && map) {
			navigator.geolocation.getCurrentPosition(function(position) {
		      const lat = position.coords.latitude;
		      const lon = position.coords.longitude;
		      Geocode.fromLatLng(lat, lon).then(
				  (response) => {
				  	map.loadImage(
					'./location.png', 
				  	function(error, image){
				  		if(!map.hasImage('self-marker')){
							map.addImage('self-marker', image);
						}

						if(map.getSource('user_location')){
					  		return;
					  	}

						map.addSource('user_location', {
							'type': 'geojson',
							'data': {type: 'Point', 'coordinates': [lon, lat]}
						});
						
						map.addLayer({
							'id': 'user_location',
							'type': 'symbol',
							'source': 'user_location',
							'layout': {
								'icon-image': 'self-marker',
								'icon-size': 0.05,
							}
						});

						['user_location', 'places', 'guides', 'posts', 'private'].forEach(function(layer){
							map.moveLayer(layer)
						});
				  	});
				    const address_parts = response.results[0].formatted_address.split(',')
				    const city = address_parts[1]
				    setCity(city);
				    setCoord([lon, lat])
				    if(address_parts.length > 2) {
				    	const state = address_parts[2].split(' ')[1]
				    	setState(state);
				    } else {
				    	setState('');
				    }
				  },
				  (error) => {
				    console.error(error);
				  }
				);
		    }, function(err){
		    	console.log(err)
		    });
	    } else {
	      console.log("Not Available");
	    }
	// eslint-disable-next-line
	}, [map]);

	return(
		<React.Fragment>

			<div style={{marginTop: '12px', letterSpacing: 3}} onClick={() => goToCity()}>
				
				<strong style={{fontSize: '10px', fontWeight: '700'}}>YOUR LOCATION</strong>
				<p style={{fontSize: 16, marginBottom: '0px', fontWeight: 'bold'}}>
					{(city.toUpperCase() + ', ' + state.toUpperCase()).substring(0, 20)}<br/>
					<strong style={{fontSize: 10,fontWeight: '700'}}> VIEW YOUR CURRENT CITY ></strong>
				</p>
			</div>

		</React.Fragment>
	)
}

export default UserLocation;