import React, { Fragment } from 'react';
import { Tabs } from 'antd';
import DashBoardTab from './tabs/dashboardTab.js';
import ConversationsTab from './tabs/conversationsTab.js';
import LocationTab from './tabs/locationTab.js'
import PersonalTab from './tabs/personalTab.js'
import TripsTab from './tabs/tripsTab.js'
import './sidebar.css';
import {useSelector, useDispatch} from "react-redux";
import { setSidebarTab } from "../../actions/sidebar";
const { TabPane } = Tabs;

const UncollapsedContent = () => {

    const sidebarTab = useSelector(state => state.sidebar.sidebarTab);
    const dispatch = useDispatch();

    const onChange = (activeKey) => {
      dispatch(setSidebarTab(activeKey))
    }

    return (
      <Fragment>
        <Tabs 
          centered="true" 
          className="tabs unselectable" 
          defaultActiveKey={sidebarTab}
          onChange={onChange}
          activeKey={sidebarTab}
        >
          <TabPane tab={<img alt="dashboard" style={{opacity: sidebarTab === 'dashboard' ? 1.0 : 0.6}} src='./nomad-icon.svg'/>} key="dashboard">
            <DashBoardTab/>
          </TabPane>
          <TabPane tab={<img alt="personal" style={{paddingTop: 2, width: '30px', height: '30px', opacity: sidebarTab === 'personal' ? 1.0 : 0.6}} src='./book.svg'/>} key="personal">
            <PersonalTab/>
          </TabPane>
          <TabPane tab={<img alt="trips" style={{marginTop: 2, width: '28px', height: '28px', opacity: sidebarTab === 'trips' ? 1.0 : 0.6}} src='./plane.svg'/>} key="trips">
            <TripsTab/>
          </TabPane>
          <TabPane tab={<img alt="conversations" style={{width: '26px', height: '26px', opacity: sidebarTab === 'conversations' ? 1.0 : 0.6}} src='./chat.svg'/>} key="conversations">
            <ConversationsTab/>
          </TabPane>
          <TabPane tab={<img alt="locations" style={{opacity: sidebarTab === 'locations' ? 1.0 : 0.6}} src='./locations.svg'/>} key="locations">
            <LocationTab/>
          </TabPane>
        </Tabs> 
      </Fragment>
    );
}

export default UncollapsedContent;
