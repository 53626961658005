import axios from 'axios';

import { 
	ADD_CONVERSATIONS
} from '../constants/action-types'

import {
	API_URL
} from '../constants/config'

// Conversations
export function setConversations(conversations) {
	return {
		type: ADD_CONVERSATIONS,
		conversations: conversations
	};
}

export function getConversations(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/messages`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(data => {
            const conversationsArray = data.data.messages.sort(function(a,b){
				if (b.time > a.time) return 1;
				if (a.time > b.time) return -1;
				return 0
			});
			dispatch(setConversations(conversationsArray))
        })
	}
}