import {
   SET_SELECTED_PROFILE,  SET_MY_PROFILE, SET_PROFILES
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setSelectedProfile(profile) {
	return {
		type: SET_SELECTED_PROFILE,
		selectedProfile: profile
	};
}

export function setMyProfile(profile) {
	return {
		type: SET_MY_PROFILE,
		myProfile: profile
	};
}


export function setProfiles(profiles) {
	return {
		type: SET_PROFILES,
		profiles: profiles
	};
}

export function getMyProfile(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/profiles/me`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function getProfiles(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/profiles`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(setProfiles(data.data.profiles))
		});
	}
}

export function createProfile(profile, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/profiles`, profile, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function updateProfile(profile, token) {
	return function(dispatch, getState) {
		return axios.put(`${API_URL}/profiles`, profile, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

