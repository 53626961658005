import axios from 'axios';

import { 
	ADD_PERMISSIONS
} from '../constants/action-types'

import {
	API_URL
} from '../constants/config'

// Conversations
export function setPermissions(permissions) {
	return {
		type: ADD_PERMISSIONS,
		permissions: permissions
	};
}

export function getPermissions(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/permissions`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(data => {
			dispatch(setPermissions(data.data.permissions))
        })
	}
}