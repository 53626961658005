import {
    SET_SELECTED_GIG, SET_GIGS, INITIAL_GIGS_FETCH
} from '../constants/action-types'

let initialState = {
    gigs: null, 
    initialFetch: false,
    selectedGig: null
};

export default function gigs(state=initialState, action) {
    switch (action.type) {
        case SET_SELECTED_GIG: {
            return Object.assign({}, state, {
                selectedGig: action.selectedGig
            });
        }
        case SET_GIGS: {
            return Object.assign({}, state, {
                gigs: action.gigs
            });
        }
        case INITIAL_GIGS_FETCH: {
            return Object.assign({}, state, {
                initialFetch: true
            });
        }
        default:
            return state
    }
}
