import {
   ADD_POINTS
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function addPoints(points) {
	return {
		type: ADD_POINTS,
		points: points
	};
}

export function getPoints(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/points`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(addPoints(data.data.points));
		});
	}
}