import {
    SET_MODAL
} from '../constants/action-types'

let initialState = {
    modalName: null
};

export default function modals(state=initialState, action) {
    switch (action.type) {
        case SET_MODAL: {
            return Object.assign({}, state, {
                modalName: action.modalName
            });
        }
        default:
            return state
    }
}
