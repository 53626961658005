import { ControlOutlined } from '@ant-design/icons';
import React from "react";
import { Button, Popconfirm, Col, Row, Checkbox } from 'antd';
import { useSelector } from "react-redux";

const Legend = () => {

	const map = useSelector(state => state.map.map);

	function confirm(e) {
	  console.log(e);
	}

	function cancel(e) {
	  console.log(e);
	}

	const toggleLayer = (e, layer) => {
		map.setLayoutProperty(
			layer,
			'visibility',
			e.target.checked ? 'visible' : 'none'
		);
	}

	return (
		<React.Fragment>
			<Popconfirm
			    title={
			    	<Col style={{minWidth: 100, fontSize: 11}}>
			    		<Row>
			    			<Col span={3}>
			    				<img alt="Guide Icon" style={{width: '10px', height: '10px'}} src='./guide-pin.png'/> 
			    			</Col>
			    			<Col span={18}>
			    				Nomad Guides
			    			</Col>
			    			<Col span={3}>
			    				<Checkbox defaultChecked={true} onChange={(e) => toggleLayer(e, 'guides')}/>
			    			</Col>
			    		</Row>
			    		<Row>
			    			<Col span={3}>
								<img alt="Post Icon" style={{width: '10px', height: '10px'}} src='./post.png'/> 
							</Col>
							<Col span={18}>
								Public Posts 
							</Col>
							<Col span={3}>
								<Checkbox defaultChecked={true} onChange={(e) => toggleLayer(e, 'posts')}/>
							</Col>
						</Row>
						<Row>
			    			<Col span={3}>
								<img alt="Private Icon" style={{width: '10px', height: '10px'}} src='./private-pin.png'/> 
							</Col>
							<Col span={18}>
								Private Posts 
							</Col>
							<Col span={3}>
								<Checkbox defaultChecked={true} onChange={(e) => toggleLayer(e, 'private')}/>
							</Col>
						</Row>
						<Row>
							<Col span={3}>
								<img alt="Location Icon" style={{width: '10px', height: '10px'}} src='./location-pin.png'/> 
							</Col>
							<Col span={18}>
								Nomad Stays 
							</Col>
							<Col span={3}>
								<Checkbox defaultChecked={true} onChange={(e) => toggleLayer(e, 'places')}/>
							</Col>
						</Row>
						<Row>
							<Col span={3}>
								<img alt="User Icon" style={{width: '10px', height: '10px'}} src='./user-pin.png'/> 
							</Col>
							<Col span={18}>
								Nomad Users 
							</Col>
							<Col span={3}>
								<Checkbox defaultChecked={true} onChange={(e) => toggleLayer(e, 'userLocations')}/>
							</Col>
						</Row>
			    	</Col>
			    }
			    placement="bottomRight"
			    onConfirm={confirm}
			    onCancel={cancel}
			 >
				<Button 
					type="primary" 
					shape="circle" 
					icon={<ControlOutlined />} 
					size='small'
					id='add-button'
					style={{
						position: 'fixed', right: '24px', 
						top: '94px', zIndex: 1000, boxShadow: '0px 0px 15px black'}}
				/>
			</Popconfirm>
		</React.Fragment>
	)
}

export default Legend;