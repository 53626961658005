import {
    SET_SIDEBAR_TAB, SET_SIDEBAR_STATUS
} from '../constants/action-types'

let initialState = {
    sidebarTab: "dashboard", 
    sidebarStatus: false
};

export default function sidebar(state=initialState, action) {
    switch (action.type) {
        case SET_SIDEBAR_TAB: {
            return Object.assign({}, state, {
                sidebarTab: action.sidebarTab
            });
        }
        case SET_SIDEBAR_STATUS: {
            return Object.assign({}, state, {
                sidebarStatus: action.sidebarStatus
            });
        }
        default:
            return state
    }
}
