import React, { useState } from "react";
import {notification, Modal, Button, 
	Form, Input, Rate, Upload, Select, InputNumber } from 'antd';
import { setModal } from "../../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';
import { createLocation, getLocations } from "../../../../actions/locations";
import S3FileUpload from 'react-s3';
import { bucketName, accessKeyId, secretAccessKey, region } from "../../../../constants/config";
import LocationSearch from "./locationSearch"
const { TextArea } = Input;
const key = 'notification_key';

const LocationModal = (props) => {

	const modal = useSelector(state => state.modals.modalName);
	const token = useSelector(state => state.token.token);
	const map = useSelector(state => state.map.map);
	const [address, setSelectedAddress] = useState();
	const [latLng, setLatLng] = useState([]);
	const [form] = Form.useForm();

	const locationTypes = [
		'NOMAD location', 'NOMAD partner', 'NOMAD member'
	];

	const defaultValues = {
        planet: 'Earth',
        costRange: [20, 50],
        rating: 5,
        locationType: locationTypes[0]
    }

    const setAddress = (e, latLng) => {
		setLatLng([latLng.lat, latLng.lng])
		setSelectedAddress(e);
	}

    form.setFieldsValue(defaultValues)

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { Option } = Select;

	const handleOk = (e) => {
		dispatch(setModal(null));
	};

	const handleCancel = (e) => {
		dispatch(setModal(null));
	};

	const layout = {
	  labelCol: {
	    span: 8,
	  },
	  wrapperCol: {
	    span: 16,
	  },
	};

	const submit = (values) => {
		setLoading(true);
		const {
			name,
			cost,
			rating,
			locationType,
			checkoutUrl,
			images,
			bedType,
			bathroomNo,
			bedroomNo,
			guestNo,
			description
		} = values;
		const s3Config = {
			bucketName, 
			accessKeyId, 
			secretAccessKey,
			region
		}
		let imagesPromises = images.fileList.map(function(image){
			return S3FileUpload.uploadFile(image.originFileObj, s3Config)
		});
		Promise.all(imagesPromises).then(data => {
				setLoading(false);
				dispatch(setModal(null));
				const location = {
					coordinates: [latLng[1], latLng[0]],
					address,
					name,
					cost,
					rating,
					locationType,
					checkoutUrl,
					bedType,
					bathroomNo,
					bedroomNo,
					guestNo,
					description,
					images: data.map(function(d){return d.location})
				}
				dispatch(createLocation(location, token)).then(function(){
					dispatch(getLocations(token));
					map.flyTo({
						center: [latLng[1], latLng[0]],
						zoom: 10
					});
				});
			})
			.catch(err => {
				setLoading(false);
				notification.info({
					key,
					message: 'Error!',
					description: err,
					placement: 'bottomRight'
				});
			});
	}

    return (
    	<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						New Property
			  		</div>
				}
				visible={modal === 'location'}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button disabled={loading} key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button disabled={loading} key="submit" form="newPropertyForm" type="primary" onClick={form.submit}>
		              Save
		            </Button>
		        ]}
		   >
		   <Form
		   	{...layout}
		   	id="newPropertyForm"
		   	initialValues={defaultValues}
		    form={form}
		    onFinish={submit}
		   >
		   	  <Form.Item
		        label="Address"
		        name="address"
		      >
		      	<LocationSearch setAddress={setAddress}/>
		      </Form.Item>
		      <Form.Item
		        label="Name"
		        name="name"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Input />
		      </Form.Item>
		      <Form.Item
		        label="Description"
		        name="description"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <TextArea rows={4} />
		      </Form.Item>
		      <Form.Item 
		      	name="cost" 
		      	label="Cost"
		      	rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <InputNumber
			      defaultValue={30}
			      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
			    />
		      </Form.Item>
		      <Form.Item name="rating" label="Rating">
		        <Rate />
		      </Form.Item>
		      <Form.Item
		        label="Location Type"
		        name="locationType"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Select>
		        	{	
		        		locationTypes.map((locationType, idx) => {
		        			return <Option key={idx} value={locationType}>{locationType}</Option>
		        		})
		        		
		        	}
		        </Select>
		      </Form.Item>
		      <Form.Item
		        label="Checkout URL"
		        name="checkoutUrl"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Input />
		      </Form.Item>
		      <Form.Item
		        label="Bed Type"
		        name="bedType"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Select>
			      <Option value="King">King</Option>
			      <Option value="Queen">Queen</Option>
			      <Option value="Double">Double</Option>
			      <Option value="Single">Single</Option>
			      <Option value="Bunk">Bunk</Option>
			    </Select>
		      </Form.Item>
		      <Form.Item
		      	label="# of Guests"
		        name="guestNo"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		      	<InputNumber min={1} max={10} />
		      </Form.Item>
		      <Form.Item
		      	label="# of Bathrooms"
		        name="bathroomNo"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		      	<InputNumber min={1} max={10} />
		      </Form.Item>
		      <Form.Item
		      	label="# of Bedrooms"
		        name="bedroomNo"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		      	<InputNumber min={1} max={10} />
		      </Form.Item>
		      <Form.Item
		        name="images"
		        label="Images"
		        valuePropName="images"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Upload 
		        	name="images" 
		        	multiple={true}
		        	listType="picture" 
		        	accept="image/png, image/jpeg"
		        	beforeUpload={(file) => {}}
		        >
		          <Button icon={<UploadOutlined />}>Click to upload</Button>
		        </Upload>
		      </Form.Item>
			</Form>
		 </Modal>
    )
}


export default LocationModal;