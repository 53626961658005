import { setFriends } from './friends'
import { addPoints } from './points'
import { setPosts } from './posts'
import { setVisits } from './visits'
import { addGuides } from './guides'
import { setProfiles } from './profile'
import { setLocations } from './locations'
import { setNotifications } from './notifications'
import { setDeals } from './deals'
import { setPermissions } from './permissions'
import { setConversations } from './conversations'
import { setUserLocations } from './userLocations'
import { setTrips } from './trips'
import { setDiscussions } from './discussions'
import { setCountries } from './countries'


import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function getSelf(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/self`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			const {
				friends,
				guides,
				points,
				posts,
				visits,
				profiles,
				locations,
				notifications,
				deals,
				permissions,
				messages,
				user_locations,
				trips,
				discussions,
				countries
			} = data.data;
			dispatch(setFriends(friends));
			dispatch(addPoints(points.points));
			var postsFc = {
			  "type": "FeatureCollection",
			  "features": posts
			};
			dispatch(setPosts(postsFc));
			dispatch(setVisits(visits));
			var guidesFc = {
			  "type": "FeatureCollection",
			  "features": guides
			};
			dispatch(addGuides(guidesFc));

			dispatch(setProfiles(profiles));
			dispatch(setNotifications(notifications));
			var locationsFc = {
			  "type": "FeatureCollection",
			  "features": locations
			};
			dispatch(setLocations(locationsFc));
			dispatch(setDeals(deals));
			dispatch(setPermissions(permissions));
			dispatch(setConversations(messages));
			var userLocationsFc = {
			  "type": "FeatureCollection",
			  "features": user_locations
			};
			dispatch(setUserLocations(userLocationsFc));
			dispatch(setTrips(trips));
			dispatch(setDiscussions(discussions));
			dispatch(setCountries(countries));
		});
	}
}