import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { Button, Popconfirm, Col, Row } from 'antd';
import { useDispatch } from "react-redux";
import LocationModal from './modals/location'
import TravelPinModal from './modals/travelPin'
import FeedbackModal from './modals/feedback'
import TripModal from './modals/trip'
import { setModal } from "../../../actions/modals";

const Add = () => {
	const dispatch = useDispatch();
	const [isPublic, setIsPublic] = useState(false);

	function confirm(e) {
	  console.log(e);
	}

	function cancel(e) {
	  console.log(e);
	}

	return (
		<React.Fragment>
			<Popconfirm
			    title={
			    	<Col style={{minWidth: 100}}>
			    		<Row style={{borderBottom: '1px solid black', paddingBottom: '6px'}} onClick={() => dispatch(setModal('trip'))}><img alt="personal" style={{paddingTop: 2, width: '20px', height: '20px', marginRight: '6px'}} src='./plane.svg'/>ADD A TRIP</Row>
			    		<Row style={{borderBottom: '1px solid black', paddingBottom: '6px', paddingTop: '6px'}} onClick={() => dispatch(setModal('feedback'))}><img style={{width: '20px', height: '20px', marginRight: '6px'}} alt="dashboard" src='./nomad-icon.svg'/> LEAVE FEEDBACK</Row>
			    		<Row style={{borderBottom: '1px solid black', paddingBottom: '6px', paddingTop: '6px'}} onClick={() => {
			    			setIsPublic(false);
			    			dispatch(setModal('travelPin'))
			    		}}><img alt="personal" style={{paddingTop: 2, width: '20px', height: '20px', marginRight: '6px'}} src='./book.svg'/>PERSONAL POST</Row>
			    		<Row style={{borderBottom: '1px solid black', paddingBottom: '6px', paddingTop: '6px'}} onClick={() => {
			    			setIsPublic(true);
			    			dispatch(setModal('travelPin'))
			    		}}><img alt="locations" style={{width: '20px', height: '20px', marginRight: '6px'}} src='./chat.svg'/>PUBLIC POST</Row>
			    		<Row style={{paddingTop: '6px'}} onClick={() => dispatch(setModal('location'))}><img alt="locations" style={{width: '20px', height: '20px', marginRight: '6px'}} src='./locations.svg'/>CREATE A STAY</Row>
			    	</Col>
			    }
			    placement="bottomRight"
			    onConfirm={confirm}
			    onCancel={cancel}
			 >
				<Button 
					type="primary" 
					shape="circle" 
					icon={<PlusOutlined />} 
					size='large'
					id='add-button'
					style={{
						position: 'fixed', right: '24px', 
						bottom: '24px', zIndex: 1000, boxShadow: '0px 0px 15px black'}}
				/>
			</Popconfirm>
			<LocationModal/>
			<TravelPinModal public={isPublic}/>
			<FeedbackModal/>
			<TripModal/>
		</React.Fragment>
	)
}

export default Add;