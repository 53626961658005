import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { Typography, Divider, Collapse, Card } from 'antd';
const { Text, Title } = Typography;
const { Panel } = Collapse;

const DashBoardTab = () => {
	const {
	    user
	} = useAuth0();

	const guides = useSelector(state => state.guides.guides);
	const deals = useSelector(state => state.deals.deals);
	const [activeKeys, setActiveKeys] = useState(['2']);
	const [allDeals, setAllDeals] = useState(false);
	const [allGuides, setAllGuides] = useState(false);

	const seeAllDeals = (e) => {
		e.stopPropagation();
		const newActiveKeys = activeKeys.concat(['0']);
		setActiveKeys(newActiveKeys);
		setAllDeals(true);
	};

	const seeAllGuides = (e) => {
		e.stopPropagation();
		const newActiveKeys = activeKeys.concat(['1']);
		setActiveKeys(newActiveKeys);
		setAllGuides(true);
	};

	const changeActivePanels = (e) => {
		setActiveKeys(e);
	}

	return(
		<div className="tab-container">
			<div className='tab-content'>
			<Title level={2} style={{color: 'black', fontWeight: 'bold', fontSize: '30px', letterSpacing: '2px'}}>Welcome Back, <br/>{user ? user.given_name : null }</Title>
			<Collapse activeKey={activeKeys} onChange={(e) => changeActivePanels(e)} bordered={false} defaultActiveKey={['1']} style={{backgroundColor: '#F5F5F5'}}>
				<Panel header={<p><strong>Nomad Deals</strong><span onClick={(e) => seeAllDeals(e)} style={{float: 'right'}}> {allDeals ? null : 'See all >'}</span></p>} key="0">
					{ 
						(deals && guides) ? deals.slice(0, allDeals ? deals.length : 1).map(function(deal, idx){
							return <a href={deal.url} key={idx}>
									<div className="deal-card">
										<img alt="logo" style={{maxHeight: '110px', maxWidth: '100%'}} src={deal.image}/>
										<p style={{margin: 0}}><strong>{deal.title}</strong></p>
										<p>{deal.description}</p>
									</div>
								</a>
						}) : null
					} 
				</Panel>
				<Panel header={<p><strong>Nomad Guides</strong><span onClick={(e) => seeAllGuides(e)} style={{float: 'right'}}> {allGuides ? null : 'See all >'}</span></p>} key="1">
					{ 
						guides ? guides.features.slice(0, allGuides ? guides.length : 1).map(function(guide, idx){
							return <a rel="noreferrer" target="_blank" download href={guide.properties.pdf} key={idx}>
								<Card
								    style={{marginTop: '8px'}}
								    cover={<div>
									    	
							    				<h3 style={{
							    					width: '100%', 
							    					height: '200px',
							    					// eslint-disable-next-line
							    					backgroundImage: 'url("'  + guide.properties.image + '")',
							    					backgroundSize: 'cover',
							    					margin: '0px'
							    				}}>
						    					</h3>
					    					</div>
					    				}
								   >
								  </Card> 
							  </a>
						}) : null
					} 
				</Panel>
				<Panel header={<p><strong>Nomad Updates</strong><span onClick={(e) => console.log(e)} style={{float: 'right'}}> See all > </span></p>} key="2">
					<Divider style={{ borderTop: '1px solid rgba(255, 255, 255, 1.0)'}}/>
					<img style={{width: '100%'}} draggable="false" src='./nomad-hosthouse.png' alt="Nomad Logo"/>
					<Title level={5} style={{color: 'black'}}>
						Currently giving $20.00 to the first 20 Nomad Members to try out our platform MVP! 
					</Title>
					<Text style={{color: 'black'}}>
						Additionally, $500.00 goes to the top referrer for the month of June 2021.
					</Text>
				</Panel>
			</Collapse>
			</div>
		</div>
	)
}

export default DashBoardTab;