import {
    ADD_PERMISSIONS
} from '../constants/action-types'

let initialState = {permissions: null};

export default function permissions(state=initialState, action) {
    switch (action.type) {
        case ADD_PERMISSIONS: {
            return Object.assign({}, state, {
                permissions: action.permissions
            });
        }
        default:
            return state
    }
}
