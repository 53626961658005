import {
    SET_TOKEN
} from '../constants/action-types'

let initialState = { 
    token: null
};

export default function token(state=initialState, action) {
    switch (action.type) {
        case SET_TOKEN: {
            return Object.assign({}, state, {
                token: action.token
            });
        }
        default:
            return state
    }
}
