import React, { useState } from "react";
import { Carousel, Typography, Rate, Card, Collapse, Tooltip } from 'antd';
import { setSelectedLocation } from "../../../actions/locations";
import { setMapGrid } from "../../../actions/mapgrid"
import { useSelector, useDispatch } from "react-redux";
import { setTriggerStatus } from '../../../actions/trigger.js'
import { setSidebarStatus } from '../../../actions/sidebar.js'
import { InfoCircleOutlined } from '@ant-design/icons';
const { Title } = Typography;
const { Panel } = Collapse;

const LocationTab = () => {
	const locations = useSelector(state => state.locations.locations);
	const dispatch = useDispatch();
	const [seeAllMemberLocations, setSeeAllMemberLocations] = useState(false);
	const [seeAllPartnerLocations, setSeeAllPartnerLocations] = useState(false);

	const locationSelect = (location) => {
		dispatch(setSelectedLocation(location));
		dispatch(setMapGrid('location'));
		dispatch(setTriggerStatus(false));
		dispatch(setSidebarStatus(true));
	};

	return(
		<div className="tab-container">
		<div className="tab-content">
			<Collapse bordered={false} defaultActiveKey={['0']} style={{backgroundColor: '#F5F5F5'}}>
				<Panel header={<p><strong>Member Locations</strong> <Tooltip placement="right" title={'ⓘ These are locations hosted by other NOMAD members, and are reflected by a green dot on the map. If you haven’t already, you can include your own listing for other members to contact you about. NOTE: we don’t handle any payments, take any commissions, nor are we responsible for any corresponding liabilities, but feel free to contact members directly about their hosted locations - and whether you have a free space on a couch, or a mansion for rent, don’t be afraid to list it! '}><InfoCircleOutlined style={{marginLeft: 4}}/></Tooltip><span onClick={(e) => {e.stopPropagation(); setSeeAllMemberLocations(e)}} style={{float: 'right'}}> {seeAllMemberLocations? null : 'See all >'}</span></p>} key="0">
					{
						locations ? locations.features.slice(0, seeAllMemberLocations ? locations.features.length : 1).filter(
								function(feature){
									return feature.properties.locationType === 'NOMAD member';
								}
							).map(function(location, idx){
							return <Card
								    hoverable
								    style={{ marginTop: '24px', fontFamily: 'Open Sans', borderStyle: 'none' }}
								    key={idx}
								    cover={
								    	<Carousel>
									    	{
									    		location.properties.images.map(function(image, idx){
									    			return <div key={idx} style={{borderRadius: '10px'}}>
									    				<div style={{
									    					width: '100%', 
									    					height: '200px',
									    					backgroundImage: 'url("'+ image+ '")',
									    					backgroundSize: 'cover',
									    					borderRadius: '10px',
									    					borderCollapse: 'separate', 
									    					margin: '0px'
									    				}}>
									    				</div>
									    			</div>
									    		})
									    	}
								    	</Carousel>
									}
								    onClick={() => locationSelect(location)}
								   >
								   		<Title level={4} style={{marginTop: 8, paddingLeft: 8, paddingRight: 8}}> {location.properties.name} </Title>
								   		<p style={{fontSize: 10, paddingLeft: 8, paddingRight: 8}}>
								   			{location.properties.guestNo} Guests - 
											{' ' + location.properties.bedroomNo} Bedrooms -
											{' ' + location.properties.bathroomNo} Bathrooms -
											{' ' + location.properties.bedType} Bed - 
											${' ' + location.properties.cost} / Night -
											{' ' + location.properties.locationType}
								   		</p>
								    	<Rate style={{float: 'left', paddingLeft: 8, paddingRight: 8, paddingBottom: 8}}allowHalf disabled={true} defaultValue={location.properties.rating}/>
								 </Card>
						}) : null
					}
				</Panel>
				<Panel header={<p><strong>Partner Locations</strong> <Tooltip placement="right" title={'ⓘ These are locations hosted by NOMAD Partners. We may take small, market-rate commissions on bookings from non-premium NOMAD members. '}><InfoCircleOutlined style={{marginLeft: 4}}/></Tooltip><span onClick={(e) => {e.stopPropagation(); setSeeAllPartnerLocations(e)}} style={{float: 'right'}}> {seeAllPartnerLocations? null : 'See all >'}</span></p>} key="1">
					{
				locations ? locations.features.slice(0, seeAllPartnerLocations ? locations.features.length : 1).filter(
						function(feature){
							return feature.properties.locationType === 'NOMAD partner';
						}
					).map(function(location, idx){
					return <Card
							    hoverable
							    style={{ marginTop: '24px', fontFamily: 'Open Sans', borderStyle: 'none' }}
							    key={idx}
							    cover={
							    	<Carousel>
								    	{
								    		location.properties.images.map(function(image, idx){
								    			return <div key={idx} style={{borderRadius: '10px'}}>
								    				<div style={{
								    					width: '100%', 
								    					height: '200px',
								    					backgroundImage: 'url("'+ image+ '")',
								    					backgroundSize: 'cover',
								    					borderRadius: '10px',
								    					borderCollapse: 'separate', 
								    					margin: '0px'
								    				}}>
								    				</div>
								    			</div>
								    		})
								    	}
							    	</Carousel>
								}
							    onClick={() => locationSelect(location)}
							   >
							   		<Title level={4} style={{marginTop: 8, paddingLeft: 8, paddingRight: 8}}> {location.properties.name} </Title>
							   		<p style={{fontSize: 10, paddingLeft: 8, paddingRight: 8}}>
							   			{location.properties.guestNo} Guests - 
										{' ' + location.properties.bedroomNo} Bedrooms -
										{' ' + location.properties.bathroomNo} Bathrooms -
										{' ' + location.properties.bedType} Bed - 
										${' ' + location.properties.cost} / Night -
										{' ' + location.properties.locationType}
							   		</p>
							    	<Rate style={{float: 'left', paddingLeft: 8, paddingRight: 8, paddingBottom: 8}}allowHalf disabled={true} defaultValue={location.properties.rating}/>
							 </Card>
					}) : null
				}
				</Panel>
			</Collapse>
		</div>
		</div>
	)
}

export default LocationTab;