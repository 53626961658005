import {
    SET_ON_CLICK, RAISE_RESIZE_FLAG, 
    LOWER_RESIZE_FLAG, SET_MAP, SET_GEOCODER, 
    SET_FEATURES_IN_VIEW, SET_MINI_MAP
} from '../constants/action-types'

export function setOnClick(onClick) {
	return {
		type: SET_ON_CLICK,
		onClick: onClick
	};
}

export function setMap(map) {
	return {
		type: SET_MAP,
		map: map
	};
}

export function setMiniMap(map) {
	return {
		type: SET_MINI_MAP,
		miniMap: map
	};
}

export function setGeocoder(geocoder) {
	return {
		type: SET_GEOCODER,
		geocoder: geocoder
	};
}

export function raiseResizeFlag() {
	return {
		type: RAISE_RESIZE_FLAG,
		resizeFlag: true
	};
}

export function lowerResizeFlag() {
	return {
		type: LOWER_RESIZE_FLAG,
		resizeFlag: false
	};
}

export function setFeaturesInView(featuresInView) {
	return {
		type: SET_FEATURES_IN_VIEW,
		featuresInView: featuresInView
	};
}