import React, { useState, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Collapse, AutoComplete, Row, Comment, Avatar, Rate, Tooltip } from 'antd';
import { CheckOutlined, StarOutlined, CloseOutlined, DeleteOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { createVisit, getVisits, deleteVisit, setVisits } from '../../../actions/visits';
import {
	API_URL
} from '../../../constants/config';
import axios from 'axios';
import { setMapGrid } from "../../../actions/mapgrid";
import { setSidebarStatus } from "../../../actions/sidebar";
import { setTriggerStatus } from '../../../actions/trigger.js'
import { deletePost, getPosts, setSelectedPost } from "../../../actions/posts";
import { setModal } from "../../../actions/modals.js"

const { Option } = AutoComplete;
const { Title } = Typography;

const { Panel } = Collapse;


const PersonalTab = () => {
	const countries = useSelector(state => state.countries.countries);
	const [options, setOptions] = useState(countries);
	const [countryCache, setCountryCache] = useState([]);
	const [tags, setTags] = useState([]);
	const [visitedCountries, setVisitedCountries] = useState([]);
	const [bucketListCountries, setBucketListCountries] = useState([]);
	const [hoveredTags, setHoveredTags] = useState([]);
	const [seeAllPosts, setSeeAllPosts] = useState(false);
	const posts = useSelector(state => state.posts.posts);
	const visits = useSelector(state => state.visits.visits);
	const token = useSelector(state => state.token.token);
	const permissions = useSelector(state => state.permissions.permissions);
	const sidebarTab = useSelector(state => state.sidebar.sidebarTab);
	const map = useSelector(state => state.map.map);
	const dispatch = useDispatch();

	const hover = (tag) => {
		let concatTags = hoveredTags.concat([tag]);
		setHoveredTags(concatTags);
	}

	const unhover = (tag) => {
		const index = hoveredTags.indexOf(tag);
		setHoveredTags(hoveredTags.splice(index, 1));
	}

	const selectPost = (post) => {
    	dispatch(setMapGrid('post'));
    	dispatch(setSelectedPost(post));
    	dispatch(setTriggerStatus(false));
    	dispatch(setSidebarStatus(true));
    }

    const removePost = async (post) => {
		dispatch(deletePost(post.properties.post_id, token)).then(function(){
			dispatch(getPosts(token));
		})	}

	useLayoutEffect(() => {
		dispatch(getVisits(token)).then(function(data){
			dispatch(setVisits(data.data.visits));
		});
	// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(sidebarTab !== 'personal'){
			map.removeLayer('country');
			map.removeLayer('bucketList');
		} else {
			map.addLayer({
					'id': 'bucketList',
					'type': 'fill',
					'source': 'bucketList', // reference the data source
					'layout': {},
					'paint': {
						'fill-color': '#FFFFFF', // blue color fill
						'fill-opacity': 0.35
					}
			});
			map.addLayer({
						'id': 'country',
						'type': 'fill',
						'source': 'country', // reference the data source
						'layout': {},
						'paint': {
							'fill-color': '#772F39', // blue color fill
							'fill-opacity': 0.5
						}
				});
			['user_location', 'places', 'guides', 'gigs', 'posts', 'private'].forEach(function(layer){
				map.moveLayer(layer)
			});
		}
	}, [sidebarTab, map])

	const shader = (values) => {
		const visitPromises = values.filter(function(visit){
			return !visit.bucketList
		}).map(function(visit){
			let cachedValue = null;
			let visitCache = countryCache.reduce(function(value, country){
				if(country.country){
					const cacheHit = (visit.name.toLowerCase() === country.country.name);
					if (cacheHit) {
						cachedValue = country.country;
					}
					return value || cacheHit;
				} else {
					return value;
				}
			}, false);

			if(visitCache) {
				return new Promise((resolve, reject) => {
					resolve({
						'data': {
							'country': cachedValue
						}
					})
				})
			} 
			else {
				return axios.get(`${API_URL}/countries`, {
			  	  headers: {
				    'Authorization': `Bearer ${token}`
				  },
				  params: {
				  	name: visit.name.toLowerCase()
				  }
				});
			}
		});
		const bucketListPromises = values.filter(function(visit){
			return visit.bucketList
		}).map(function(visit){
			let cachedValue = null;
			let visitCache = countryCache.reduce(function(value, country){
				if(country.country){
					const cacheHit = (visit.name.toLowerCase() === country.country.name);
					if (cacheHit) {
						cachedValue = country.country;
					}
					return value || cacheHit;
				} else {
					return value;
				}
			}, false);

			if(visitCache) {
				return new Promise((resolve, reject) => {
					resolve({
						'data': {
							'country': cachedValue
						}
					})
				})
			} 
			else {
				return axios.get(`${API_URL}/countries`, {
			  	  headers: {
				    'Authorization': `Bearer ${token}`
				  },
				  params: {
				  	name: visit.name.toLowerCase()
				  }
				});
			}
		});
		Promise.all(visitPromises).then(function(data){
			let countryData = data.map(function(d){
				return d.data
			}).filter(function(d){
				return d
			});
			const cC = [...new Set([...countryCache,...countryData])]
			setCountryCache(cC)
			const filtered = data.filter(function(d){
				return d.data.country;
			}).map(function(d){
				return d.data.country.geometry
			}).map(function(d){
				return {
					type: "Feature",
					properties: {},
					geometry: d
				}
			});
			const fc = {
				"type": "FeatureCollection",
				"features": filtered
			};

			if(map.getSource('country')){
				map.getSource('country').setData(fc)
			} else {
				map.addSource('country', {
					'type': 'geojson',
					'data': fc
				});
			}
			
			if(!map.getLayer('country')){
				map.addLayer({
						'id': 'country',
						'type': 'fill',
						'source': 'country', // reference the data source
						'layout': {},
						'paint': {
							'fill-color': '#772F39', // blue color fill
							'fill-opacity': 0.5
						}
				});
			}

			['user_location', 'places', 'guides', 'gigs', 'posts', 'private'].forEach(function(layer){
				map.moveLayer(layer)
			});
		});
		Promise.all(bucketListPromises).then(function(data){
			let countryData = data.map(function(d){
				return d.data
			}).filter(function(d){
				return d
			});
			const cC = [...new Set([...countryCache,...countryData])]
			setCountryCache(cC)
			const filtered = data.filter(function(d){
				return d.data.country;
			}).map(function(d){
				return d.data.country.geometry
			}).map(function(d){
				return {
					type: "Feature",
					properties: {},
					geometry: d
				}
			});
			const fc = {
				"type": "FeatureCollection",
				"features": filtered
			};

			if(map.getSource('bucketList')){
				map.getSource('bucketList').setData(fc)
			} else {
				map.addSource('bucketList', {
					'type': 'geojson',
					'data': fc
				});
			}
			
			if(!map.getLayer('bucketList')){
				map.addLayer({
						'id': 'bucketList',
						'type': 'fill',
						'source': 'bucketList', // reference the data source
						'layout': {},
						'paint': {
							'fill-color': '#FFFFFF', // blue color fill
							'fill-opacity': 0.35
						}
				});
			}
			['user_location', 'places', 'guides', 'gigs', 'posts'].forEach(function(layer){
				map.moveLayer(layer)
			});
		});
	}

	useEffect(() => {
		if(!visits){
			return;
		}
		shader(visits);
		setTags(visits);
		// eslint-disable-next-line
	}, [visits, map]);

	useEffect(() => {
		if(!tags){
			return;
		}
		setBucketListCountries(tags.filter(function(tag){
			return tag.bucketList;
		}).map(function(tag){
			return tag.name;
		}))
		setVisitedCountries(tags.filter(function(tag){
			return !tag.bucketList;
		}).map(function(tag){
			return tag.name;
		}))
	}, [tags])

	const onSelect = (data, type) => {
		let newTags = tags.concat([{name: data}]);
		dispatch(createVisit({name: data, bucketList: type}, token)).then(function(value){
			newTags = tags.concat([value.data.visit])
			setTags(newTags)
			shader(newTags);
		});
	};

	const onClose = (tag) => {
		const cleanedTags = tags.filter(function(t){
			return t.name !== tag.name;
		});
		setTags(cleanedTags);
		dispatch(deleteVisit(tag['visit_id'], token));
		shader(cleanedTags);
	}

	const handleSearch = (value) => {
	    const options = countries.filter(function(country){
	    	return country.toLowerCase().indexOf(value.toLowerCase()) === 0
	    });
	    setOptions(options);
	 };

	return(
		<div style={{maxHeight: '70vh'}}>
			<div style={{textAlign: 'left', paddingLeft: '16px', paddingRight: '16px', overflowY: 'scroll' }}>
				<Row>
					<Title level={4}>PERSONAL TRAVEL MAP</Title>
					<Tooltip placement="right" title={'ⓘ Fill in the countries you’ve traveled to (Visited), along with the countries you’d like to travel to (Bucket List). After you do this, the map will colorize the countries you’ve selected. Your profile will display both lists along with the colorized map, so your followers & friends can view them! Additionally, Personal Posts are travel notes *for your eyes only,* and will show up on your personal map as white pins (only if “location” is selected).'}><InfoCircleOutlined style={{fontSize: 12, marginLeft: 8, marginTop: 8}}/></Tooltip>
				</Row>
				<Collapse bordered={false} defaultActiveKey={['1']} style={{backgroundColor: '#F5F5F5'}}>
				    <Panel header={<p>Visited ({tags.filter(function(tag){return !tag.bucketList}).length}) </p>} key="1">
					    <AutoComplete
					        style={{
					        	width: '100%',
					        	marginTop: '8px',
					        	marginBottom: '8px',
					        	backgroundColor: '#353639',
					        	padding: 4,
					        	borderRadius: 7
					        }}
					        className="countryselect"
					        onSearch={handleSearch}
					        onSelect={(e) => onSelect(e, false)}
					        placeholder="Add country"
					    >

					      {options.map((country, index) => (
					        <Option key={index} disabled={visitedCountries.indexOf(country) > -1} value={country}>
					          {country}
					        </Option>
					      ))}
					    </AutoComplete>
						{
				    		tags.filter(function(tag){return !tag.bucketList}).sort(function(a, b){
				    			return a.name.localeCompare(b.name)
				    		}).map(function(tag, idx){
				    			return <Row key={idx} onMouseEnter={() => hover(tag.name)} onMouseLeave={() => unhover(tag.name)}> 
				    					<CheckOutlined style={{marginRight: 6, marginTop: 4}} /> {tag.name} <CloseOutlined onClick={() => onClose(tag)} style={{float: 'right', marginTop: 4, display: (hoveredTags.indexOf(tag.name) > 0) ? 'block' : 'none'}}/>
				    				</Row>
				    		})
				    	}
				    </Panel>
				    <Panel header={<p>Bucket List ({tags.filter(function(tag){return tag.bucketList}).length})</p>} key="2">
				      <AutoComplete
					        style={{
					        	width: '100%',
					        	marginTop: '8px',
					        	marginBottom: '8px',
					        	backgroundColor: '#353639',
					        	padding: 4,
					        	borderRadius: 7
					        }}
					        onSearch={handleSearch}
					        onSelect={(e) => onSelect(e, true)}
					        className="countryselect"
					        placeholder="Add country"
					    >

					      {options.map((country) => (
					        <Option key={country} disabled={bucketListCountries.indexOf(country) > -1} value={country}>
					          {country}
					        </Option>
					      ))}
					    </AutoComplete>
				      {
				    		tags.filter(function(tag){return tag.bucketList}).sort(function(a, b){
				    			return a.name.localeCompare(b.name)
				    		}).map(function(tag, idx){
				    			return <Row key={idx} onMouseEnter={() => hover(tag.name)} onMouseLeave={() => unhover(tag.name)}> 
			    					<StarOutlined style={{marginRight: 6, marginTop: 4}} /> {tag.name} <CloseOutlined onClick={() => onClose(tag)} style={{float: 'right', marginTop: 4, display: (hoveredTags.indexOf(tag.name) > 0) ? 'block' : 'none'}}/>
			    				</Row>
				    		})
				    	}
				    </Panel>
				    <Panel header={<p>Personal Posts <PlusOutlined onClick={(e) => {e.stopPropagation(); dispatch(setModal('travelPin'))}}/><span onClick={(e) => {e.stopPropagation(); setSeeAllPosts(true)}} style={{float: 'right'}}> { seeAllPosts ? null : 'See all >'} </span></p>} key="3">
				      {
				       posts ? posts.features.filter(function(post){
				       		return !post.properties.public
				       }).slice(0, seeAllPosts ? posts.length : 1).map(function(post, idx){
				      		return <Comment
				      				  style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 10}}
								      author={
								      	<Row>
									      	<Avatar
									          size={40}
									          src={post.properties.user_avatar}
									          alt={post.properties.name}
									        />
									      	<p style={{color: 'white', marginLeft: 12}}>
									      	<strong style={{fontSize: 16}}>{post.properties.title}</strong> 
									        </p>
								        </Row>
								  	  }
								      content={
								        <p style={{color: 'white'}}>
								          {post.properties.content} <br/>
								          <Rate value={post.properties.rating || post.rating} disabled/>
								         { permissions.admin ? <DeleteOutlined style={{float: 'right', marginRight: 12}} onClick={() => removePost(post)}/> : null }
								        </p>
								      }
								      onClick={() => selectPost(post)}
								      key={idx}
								    />
				      	}) : null
				      }
				    </Panel>
			    </Collapse>
			</div>
		</div>
	)
}

export default PersonalTab;