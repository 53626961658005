import { MenuOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { Drawer, Button } from 'antd';
import { setTriggerStatus } from '../../../actions/trigger.js'
import { useSelector, useDispatch } from "react-redux";
import UncollapsedContent from '../../sidebar/uncollapsedContent'

const Trigger = () => {

	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);
	const dispatch = useDispatch();
	const triggerStatus = useSelector(state => state.trigger.triggerStatus);


	const onClose = () => {
		dispatch(setTriggerStatus(false))
	};

	function handleTouchStart(e) {
	    setTouchStart(e.targetTouches[0].clientY);
	}

	function handleTouchMove(e) {
	    setTouchEnd(e.targetTouches[0].clientY);
	}

	function handleTouchEnd() {
	    if (touchStart - touchEnd < -250) {
	        dispatch(setTriggerStatus(false))
	    }
	}

	return (
		<React.Fragment>
			<Button 
				type="primary" 
				shape="circle"
				className="hide-gt-sm"
				icon={<MenuOutlined />} 
				onClick={() => dispatch(setTriggerStatus(true))}
				size='large'
				style={{
					position: 'fixed', left: '43%', right: '50%',
					bottom: '24px', zIndex: 1000, boxShadow: '0px 0px 15px black'}}
			/>
			 <Drawer
		        placement="bottom"
		        closable={false}
		        height="90%"
		        onClose={onClose}
		        visible={triggerStatus}
		        onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
				onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
				onTouchEnd={() => handleTouchEnd()}
		      >
		        <UncollapsedContent/>
		      </Drawer>
		</React.Fragment>
	)
}

export default Trigger;