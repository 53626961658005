import {
    SET_SIDEBAR_TAB, SET_SIDEBAR_STATUS
} from '../constants/action-types'

export function setSidebarTab(sidebarTab) {
	return {
		type: SET_SIDEBAR_TAB,
		sidebarTab: sidebarTab
	};
}

export function setSidebarStatus(sidebarStatus) {
	return {
		type: SET_SIDEBAR_STATUS,
		sidebarStatus: sidebarStatus
	};
}