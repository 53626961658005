import React, { useState, useEffect } from 'react';
import { Comment, Avatar, Row, Col, Button, Modal, Form, Input } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { PlusOutlined, CaretUpOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { createComment, getComments, upvoteComment, updateComment } from "../../actions/comments";

const Comments = (props) => {

	const comments = useSelector(state => state.comments.comments);
	const token = useSelector(state => state.token.token);
	const [selectComment, setSelectComment] = useState(null);
	const [replyModal, setReplyModal] = useState(false);
	const dispatch = useDispatch();

	const {
	    user
	} = useAuth0();

	const handleReplyOk = (e) => {
		setReplyModal(false);
		setSelectComment(null);
	};

	const handleReplyCancel = (e) => {
		setReplyModal(false);
		setSelectComment(null);
	};

	const fetchComments = function(nestLevel) {
		dispatch(getComments(props.parentId, token, nestLevel))
	}

	const submitComment = function(values) {
		setReplyModal(false);
		if (!selectComment){
			values.parent_id = props.parentId;
		} else {
			values.parent_id = selectComment.reply_id
			setSelectComment(null);
		}
		dispatch(createComment(values, token)).then(function(){
			fetchComments(1)
		})
	}

	const doUpvoteComment = function(comment) {
		if(comment.locked){
			return;
		}
		comment['votes'] = parseInt(comment['votes']) + 1
		comment['locked'] = true;
		dispatch(updateComment(comment))
		dispatch(upvoteComment(comment.reply_id, token));
	}

	useEffect(() => {
		if ( !props.parentId ) {
			return
		}
		fetchComments(1);
		// eslint-disable-next-line
	}, [props.parentId, dispatch]);

	return (
		<React.Fragment>
			<Form
      			name="commentForm"
      			onFinish={submitComment}
      			style={{marginTop: 8}}
      		>
      			<Form.Item
			        name="content"
			      >
			        <Input className="comment-form" placeholder="ADD A COMMENT" suffix={
			      		<Button type="primary" htmlType="submit">
				          <PlusOutlined/>Submit
				        </Button>
			        }/>
			      </Form.Item>
      		</Form>
      		{
					comments ? comments.filter(function(comment){
						return comment.parent_id === props.parentId
					}).map(function(comment, idx){
						return <div>
								<Comment
			      				  style={{width: '100%', backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5}}
							      author={
							      	<Row >
							      	<Col style={{color: 'white', marginTop: 10, fontSize: 20, textAlign: 'center'}}>
							      		{comment.voters.indexOf(user['sub']) > -1 ? <CaretUpOutlined style={{color: 'orange'}} /> : <CaretUpOutlined style={{color: 'white'}} onClick={() => doUpvoteComment(comment)}/> }<br/> 
							      		{comment.votes}
							      	</Col>
							      	<Col style={{marginLeft: 10}}>
							      	<Avatar
							          src={comment.user_avatar}
							        />
							        </Col>
							        <Col>
							        	<p style={{color: 'white', marginLeft: 8}}>{comment.name}</p>
							        </Col>
							        <Col onClick={() => {setSelectComment(comment); setReplyModal(true)} }>
							        	<p style={{color: 'white', marginLeft: 8}}><ReloadOutlined/></p>
							        </Col>
							        </Row>
							  	  }
							      content={
							        <p style={{color: 'white', textAlign: 'left', marginLeft: 30, marginTop: -10}}>
							          {comment.content} <br/>
							        </p>
							      }
							      key={idx}
							    />
							    {
							    	comments.filter(function(subComment){
							    		return subComment.parent_id === comment.reply_id
							    	}).map(function(subComment){
							    		return <Comment
					      				  style={{width: '90%', backgroundColor: '#363639', marginTop: 8, marginLeft: 40, paddingLeft: 12, paddingRight: 8, borderRadius: 5}}
									      author={
									      	<Row >
									      	<Col style={{color: 'white', marginTop: 10, fontSize: 20, textAlign: 'center'}}>
									      		{subComment.voters.indexOf(user['sub']) > -1 ? <CaretUpOutlined style={{color: 'orange'}} /> : <CaretUpOutlined style={{color: 'white'}} onClick={() => doUpvoteComment(subComment)}/> }<br/> 
									      		{subComment.votes}
									      	</Col>
									      	<Col style={{marginLeft: 10}}>
									      	<Avatar
									          src={subComment.user_avatar}
									        />
									        </Col>
									        <Col>
									        	<p style={{color: 'white', marginLeft: 8}}>{subComment.name}</p>
									        </Col>
									        </Row>
									  	  }
									      content={
									         <p style={{color: 'white', textAlign: 'left', marginLeft: 30, marginTop: -10}}>
									          {subComment.content} <br/>
									        </p>
									      }
									      key={idx}
									    />
							    	})
							    }
						    </div>
					}) : null
				}
				<Modal
					title={
					<div
						style={{
								width: '100%',
								cursor: 'move',
							}}
							onFocus={() => {}}
							onBlur={() => {}}
						>
							New Reply
				  		</div>
					}
					footer={null}
					visible={replyModal}
					onOk={handleReplyOk}
					onCancel={handleReplyCancel}
			   >
				   	<Form
		      			name="commentForm"
		      			onFinish={submitComment}
		      			style={{marginTop: 8}}
		      		>
		      			<Form.Item
					        name="content"
					      >
					        <Input  placeholder="Comment" suffix={
					      		<Button type="primary" htmlType="submit">
						          <PlusOutlined/>Submit
						        </Button>
					        }/>
					      </Form.Item>
					     
		      		</Form>
				</Modal>

		</React.Fragment>
	)
}

export default Comments;