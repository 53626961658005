import {
	SET_RESERVATIONS
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setReservations(reservations) {
	return {
		type: SET_RESERVATIONS,
		reservations: reservations
	};
}

export function getReservations(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/reservations`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(setReservations(data.data.reservations));
		});
	}
}

export function createReservation(reservation, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/reservations`, reservation, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}
