import {
   ADD_COMMENTS, REMOVE_COMMENT, UPDATE_COMMENT
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function addComments(comments) {
	return {
		type: ADD_COMMENTS,
		comments: comments
	};
}

export function removeComment(reply_id) {
	return {
		type: REMOVE_COMMENT,
		reply_id: reply_id
	};
}

export function updateComment(comment) {
	return {
		type: UPDATE_COMMENT,
		comment: comment
	};
}

export function getComments(parent_id, token, nestLevel, parent_ids) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/replies`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }, 
		  params: {
		  	parent_id: parent_id,
		  	parent_ids: JSON.stringify(parent_ids)
		  }
		}).then(function(data){
			dispatch(addComments(data.data.replies));

			if(nestLevel === 1){
				const ids = data.data.replies.map(function(reply){
					return reply.reply_id
				})
				dispatch(getComments(null, token, 0, ids))
			}
		});
	}
}

export function upvoteComment(reply_id, token) {
	return function(dispatch, getState) {
		return axios.put(`${API_URL}/replies`, {}, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }, 
		  params: {
		  	 reply_id: reply_id
		  }
		}).then(function(comment){
			dispatch(updateComment(comment.data.replies));
		});
	}
}

export function createComment(reply, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/replies`, reply, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function deleteComment(reply_id, token) {
	return function(dispatch, getState) {
		dispatch(removeComment(reply_id))
		return axios.delete(`${API_URL}/replies`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	reply_id: reply_id
		  }
		});
	}
}