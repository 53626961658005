import {
    SET_VISITS, INITIAL_VISITS_FETCH
} from '../constants/action-types'

let initialState = {
    visits: null, 
    initialFetch: false
};

export default function visits(state=initialState, action) {
    switch (action.type) {
        case SET_VISITS: {
            return Object.assign({}, state, {
                visits: action.visits
            });
        }
        case INITIAL_VISITS_FETCH: {
            return Object.assign({}, state, {
                initialFetch: true
            });
        }
        default:
            return state
    }
}
