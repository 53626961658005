import {
    SET_FRIENDS
} from '../constants/action-types'

let initialState = {
    friends: [],
    friendIds: []
};

export default function friends(state=initialState, action) {
    switch (action.type) {
        case SET_FRIENDS: {
            return Object.assign({}, state, {
                friends: action.friends,
                friendIds: action.friends.map(function(f){return f.friend})
            });
        }
        default:
            return state
    }
}
