import React, { useState } from "react";
import { Modal, Button, Form, Input, Select, Upload, Switch, Rate, Checkbox, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { setModal } from "../../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";
import { createGig, getGigs } from "../../../../actions/gigs";
import { createGuide, getGuides } from "../../../../actions/guides";
import { createPost, getPosts } from "../../../../actions/posts";
import { createReview } from "../../../../actions/reviews";
import LocationSearch from "./locationSearch"
import { bucketName, accessKeyId, secretAccessKey, region } from "../../../../constants/config";
import S3FileUpload from 'react-s3';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const TravelPinModal = (props) => {
	const modal = useSelector(state => state.modals.modalName);
	const token = useSelector(state => state.token.token);
	const map = useSelector(state => state.map.map);
	const [formType, setFormType] = useState('Post');
	const [hasLocation, setHasLocation] = useState(false);
	const [address, setSelectedAddress] = useState();
	const [latLng, setLatLng] = useState([]);
	const [displayReview, setDisplayReview] = useState(false);
	const [form] = Form.useForm();

	const defaultValues = {
        planet: 'Earth',
        costRange: [20, 50],
        rating: 5,
        postType: 'Post',
        isPublic: props.public
    }

    form.setFieldsValue(defaultValues)

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleOk = (e) => {
		dispatch(setModal(null));
	};

	const handleCancel = (e) => {
		dispatch(setModal(null));
	};

	const selectFormType = (e) => {
		setFormType(e);
	}

	const setAddress = (e, latLng) => {
		setLatLng([latLng.lat, latLng.lng])
		setSelectedAddress(e);
	}

	const submitPost = (values) => {
		setLoading(true);
		let {
			title,
			description,
			isPublic,
			images
		} = values;

		if(!isPublic){
			isPublic = false;
		}

		const s3Config = {
			bucketName, 
			accessKeyId, 
			secretAccessKey,
			region
		}
		let imagesPromises;
		if(!images){
			imagesPromises = [];
		} else {
			imagesPromises = images.fileList.map(function(image){
				return S3FileUpload.uploadFile(image.originFileObj, s3Config)
			});
		}
		
		Promise.all(imagesPromises).then(data => {
			setLoading(false);
			dispatch(setModal(null));
			const post = {
				coordinates: hasLocation ? [latLng[1], latLng[0]] : null,
				address: hasLocation ? address : null,
				title,
				description,
				isPublic: isPublic,
				images: data.map(function(d){return d.location})
			}
			dispatch(createPost(post, token)).then(function(post){
				if(displayReview) {
					const target_id = post.data.post.post_id
					const review = {
						review: values.review,
						rate: values.rate,
						target_id: target_id
					}
					dispatch(createReview(review, token));
				}
				if(post.data.first){
					notification.open({
					    message: 'Nomad Points!',
					    description:
					      'You\'ve been awarded 100 nomad points for your first post'
					  });
				}
				dispatch(getPosts(token));
				if(hasLocation){
					map.flyTo({
						center: [latLng[1], latLng[0]],
						zoom: 10
					});
				}
				
			});
		});
	}

	const submitGuide = (values) => {
		const s3Config = {
			bucketName, 
			accessKeyId, 
			secretAccessKey,
			region
		}
		let image_obj = values.image.file.originFileObj;
		image_obj.filename = values.title.toLowerCase()+'.png'
		let doc_obj = values.document.file.originFileObj;
		doc_obj.filename = values.title.toLowerCase()+'.pdf'
		const image_promise = S3FileUpload.uploadFile(image_obj, s3Config);
		const doc_promise = S3FileUpload.uploadFile(doc_obj, s3Config);

		Promise.all([image_promise, doc_promise]).then((results) => {
			const imageResult = results.filter(function(result){
				return result.location.indexOf('.png') > 0
			})[0].location;

			const pdfResult = results.filter(function(result){
				return result.location.indexOf('.pdf') > 0
			})[0].location;

			setLoading(false);
			dispatch(setModal(null));
			const guide = {
				lat: latLng[0],
				lon: latLng[1],
				image: imageResult,
				pdf: pdfResult,
				name: values.title.toLowerCase(),
				address: address
			}
			dispatch(createGuide(guide, token)).then(function(){
				dispatch(getGuides(token));
				map.flyTo({
					center: [latLng[1], latLng[0]],
					zoom: 10
				})
			});
		});
		
	}

	const submitGig = (values) => {
		setLoading(true);
		const {
			title,
			description,
		} = values;
		setLoading(false);
		dispatch(setModal(null));
		const gig = {
			coordinates: [latLng[1], latLng[0]],
			address,
			title,
			description
		}
		dispatch(createGig(gig, token)).then(function(){
			dispatch(getGigs(token));
			map.flyTo({
				center: [latLng[1], latLng[0]],
				zoom: 10
			})
		});
	}

	const submit = (values) => {
		if (values.postType === 'Post'){
			submitPost(values);
		} else if (values.postType === 'Gig') {
			submitGig(values);
		}
		else {
			submitGuide(values);
		}
		
	}

	const layout = {
	  labelCol: {
	    span: 6,
	  },
	  wrapperCol: {
	    span: 18,
	  },
	};

	const reviewForm = <React.Fragment>
		<Form.Item
	        label="Review"
	        name="review"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
	        <Input />
	    </Form.Item>
		<Form.Item
	        label="Rate"
	        name="rate"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
			<Rate />
		</Form.Item>

	</React.Fragment>

	const gigForm = <React.Fragment>
		<Form.Item
	        label="Title"
	        name="title"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
	        <Input />
	      </Form.Item>
	      <Form.Item
	        label="Description"
	        name="description"
	      >
			 <Input.TextArea rows={6}/>
		  </Form.Item>
	</React.Fragment>

	const guideForm = <React.Fragment>
		<Form.Item
	        label="Title"
	        name="title"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
	      <Input/>
	    </Form.Item>
		<Form.Item
	        name="image"
	        label="Image"
	        valuePropName="image"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
	        <Upload 
	        	name="image" 
	        	listType="picture" 
	        	accept="image/png"
	        	beforeUpload={(file) => {}}
	        >
	          <Button icon={<UploadOutlined />}>Click to upload</Button>
	        </Upload>
	      </Form.Item>
	      <Form.Item
	        name="document"
	        label="Document"
	        valuePropName="document"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
	        <Upload 
	        	name="document"
	        	accept="application/pdf"
	        	beforeUpload={(file) => {}}
	        >
	          <Button icon={<UploadOutlined />}>Click to upload</Button>
	        </Upload>
	      </Form.Item>
	</React.Fragment>


	const postForm = <React.Fragment>
		<Form.Item
	        label="Title"
	        name="title"
	        rules={[
	          {
	            required: true,
	          }
	        ]}
	      >
	        <Input />
	      </Form.Item>
	      <Form.Item
	        label="Description"
	        name="description"
	      >
			 <Input.TextArea rows={6}/>
		  </Form.Item>
		  <Form.Item
	        name="images"
	        label="Images"
	        valuePropName="images"
	      >
	        <Upload 
	        	name="images" 
	        	multiple={true}
	        	listType="picture" 
	        	accept="image/png, image/jpeg"
	        	beforeUpload={(file) => {}}
	        >
	          <Button icon={<UploadOutlined />}>Click to upload</Button>
	        </Upload>
	      </Form.Item>
	      {displayReview ? null : <p onClick={() => setDisplayReview(true)}> <PlusOutlined/> Include Review </p> }
	      {displayReview ? reviewForm : null}
	</React.Fragment>

    return (
    	<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						New Travel Pin
			  		</div>
				}
				visible={modal === 'travelPin'}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button disabled={loading} key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button disabled={loading} key="submit" form="newPropertyForm" type="primary" onClick={form.submit}>
		              Save
		            </Button>
		        ]}
		   >
		  <Form
		   	{...layout}
		   	id="newPropertyForm"
		   	initialValues={defaultValues}
		    form={form}
		    onFinish={submit}
		   >
			  <Form.Item
		        label="Post Type"
		        name="postType"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Select onChange={(e) => selectFormType(e)}>
			      <Option value="Post">Point of Interest</Option>
			    </Select>
		      </Form.Item>
		      <Form.Item
		        label="Location?"
		        name="hasLocation"
		      >
		        <Checkbox onChange={(e) => setHasLocation(e.target.checked)}/>
		      </Form.Item>
		      { hasLocation ?  <Form.Item
		        label="Address"
		        name="address"
		      >
		      	<LocationSearch setAddress={setAddress}/>
		      </Form.Item> : null }
		      { formType === 'Guide' ? guideForm : null}
		      { formType === 'Gig' ? gigForm : null}
		      { formType === 'Post' ? postForm : null}
		      <Form.Item
		        label="Public"
		        name="isPublic"
		        valuePropName="checked"
		      >
				 <Switch />
			  </Form.Item>
		    </Form>
		   
		 </Modal>
    )
}


export default TravelPinModal;