import React, { useState, useEffect } from "react";
import { Modal, Button, Form, DatePicker, Select, List, Row, Col } from 'antd';
import { setModal } from "../../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";
import { createTravel } from "../../../../actions/travels";
import LocationSearch from "./locationSearch"
import { getFlights } from "../../../../actions/flights";
import axios from 'axios'
const { RangePicker } = DatePicker;
const { Option } = Select;


const TravelModal = (props) => {
	const modal = useSelector(state => state.modals.modalName);
	const token = useSelector(state => state.token.token);
	const [startAddress, setStartAddress] = useState(null);
	const [startCoords, setStartCoords] = useState(null);
	const [startIATA, setStartIATA] = useState(null);
	const [endIATA, setEndIATA] = useState(null);
	const [endAddress, setEndAddress] = useState(null);
	const [endCoords, setEndCoords] = useState(null);
	const [flights, setFlights] = useState([]);
	const [range, setRange] = useState(null)
	const [form] = Form.useForm();

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleOk = (e) => {
		dispatch(setModal(null));
	};

	const handleCancel = (e) => {
		dispatch(setModal(null));
	};

	const getIATACode = (coords) => {
		return axios.get('https://iatageo.com/getCode/' + coords.lat + '/' + coords.lng);
	}

	useEffect(() => {
		if(!startIATA || !endIATA || !range){
			return;
		}
		dispatch(getFlights(startIATA, endIATA, range[0].format("YYYY-MM-DD"), token)).then(function(data){
			if(!data.data.data || !data.data.data.offers){
				return;
			}
			setFlights(data.data.data.offers);
		});
		// eslint-disable-next-line
	}, [startIATA, endIATA, range])

	const startAddressHandler = (address, coords) => {
		setStartCoords(coords);
		setStartAddress(address);
		getIATACode(coords).then((result) => {
			setStartIATA(result.data.IATA)
		});
	}

	const endAddressHandler = (address, coords) => {
		setEndCoords(coords);
		setEndAddress(address);
		getIATACode(coords).then((result) => {
			setEndIATA(result.data.IATA)
		});
	}

	const layout = {
	  labelCol: {
	    span: 8,
	  },
	  wrapperCol: {
	    span: 16,
	  },
	};

	const submit = (values) => {
		const {
			dates,
			mode
		} = values;
		setLoading(false);
		dispatch(setModal(null));
		const travel = {
			startDate: dates[0],
			endDate: dates[1],
			startAddress,
			endAddress,
			startCoords,
			endCoords,
			trip_id: props.selectedTrip.trip_id,
			mode
		}
		dispatch(createTravel(travel, token)).then(function(travel){
			props.onNewTravel(travel);
		});
	}

    return (
    	<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						Travel Builder
			  		</div>
				}
				visible={modal === 'travel'}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button disabled={loading} key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button disabled={loading} key="submit" form="newPropertyForm" type="primary" onClick={form.submit}>
		              Next
		            </Button>
		        ]}
		   >
		   <Form
		   	{...layout}
		   	id="newPostForm"
		    form={form}
		    onFinish={submit}
		   >
			  <Form.Item
		        label="Where will you start?"
		        name="startAddress"
		      >
		      	<LocationSearch setAddress={startAddressHandler}/>
		      </Form.Item>
		      <Form.Item
		        label="Where will you go?"
		        name="endAddress"
		      >
		      	<LocationSearch setAddress={endAddressHandler}/>
		      </Form.Item>
		      <Form.Item
		        label="Dates"
		        name="dates"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <RangePicker onChange={setRange} />
		      </Form.Item>
		      <Form.Item
		        label="Mode"
		        name="mode"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Select>
		        	<Option value={'Car'}>Car</Option>
		        	<Option value={'Flight'}>Flight</Option>
		        	<Option value={'Train'}>Train</Option>
		        	<Option value={'Bus'}>Bus</Option>
		        </Select>
		      </Form.Item>
			</Form>
			<List
			    itemLayout="horizontal"
			    dataSource={flights}
			    style={{maxHeight: 250, overflowY: 'scroll'}}
			    renderItem={item => (
			      <List.Item>
			        <List.Item.Meta
			          avatar={<img alt="plane" src="./plane.svg" />}
			          title={<span style={{color: 'black'}} >${item.base_amount}</span>}
			          description={
			          	<Col>
			          	<Row style={{color: 'black'}}>
			          		{item.slices[0].origin.iata_code} - {item.slices[0].destination.iata_code}
			          	</Row>
			          	<Row style={{color: 'black'}}>
			          		{item.slices[0].segments[0].departing_at} - {item.slices[0].segments[0].arriving_at}
			          	</Row>
			          	</Col>
			          }
			        />
			      </List.Item>
			    )}
			  />
		 </Modal>
    )
}


export default TravelModal;