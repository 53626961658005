import axios from 'axios';

import { 
	SET_USER_LOCATIONS
} from '../constants/action-types'

import {
	API_URL
} from '../constants/config'

export function setUserLocations(userLocations) {
	return {
		type: SET_USER_LOCATIONS,
		userLocations: userLocations
	};
}

export function getUserLocations(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/user_locations`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(data => {
			dispatch(setUserLocations(data.data.user_locations))
        })
	}
}

export function createUserLocation(userLocation, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/user_locations`, userLocation, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}