import React, { Fragment } from 'react';
import './sidebar.css';
import { useDispatch, useSelector } from "react-redux";
import { setSidebarStatus, setSidebarTab } from "../../actions/sidebar";
import { Menu } from 'antd';


const CollapsedContent = () => {

  const dispatch = useDispatch();
  const sidebarTab = useSelector(state => state.sidebar.sidebarTab);

  const onClick = (key) => {
    dispatch(setSidebarStatus(false))
    dispatch(setSidebarTab(key.key))
  }

  return (
    <Fragment>
      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" style={{paddingTop: 84}}>
        <Menu.Item key="dashboard" onClick={onClick} style={{opacity: sidebarTab === 'dashboard' ? 1.0 : 0.6}}>
          <img alt="dashboard" src='./nomad-icon.svg'/> 
        </Menu.Item>
        <Menu.Item key="personal" onClick={onClick} style={{color: 'black'}}>
          <img alt="personal" style={{paddingTop: 2, width: '30px', height: '30px', opacity: sidebarTab === 'personal' ? 1.0 : 0.6}} src='./book.svg'/>
        </Menu.Item>
        <Menu.Item key="trips" onClick={onClick}  style={{color: 'black', textAlign: 'center'}}>
          <img alt="trips" style={{marginTop: 2, width: '28px', height: '28px', opacity: sidebarTab === 'trips' ? 1.0 : 0.6}} src='./plane.svg'/>
        </Menu.Item>
        <Menu.Item key="conversations" onClick={onClick} style={{color: 'black'}}>
          <img alt="conversations" style={{width: '26px', height: '26px', opacity: sidebarTab === 'conversations' ? 1.0 : 0.6}} src='./chat.svg'/>
        </Menu.Item>
        <Menu.Item key="locations" onClick={onClick}  style={{color: 'black'}}>
          <img alt="locations" style={{opacity: sidebarTab === 'locations' ? 1.0 : 0.6}} src='./locations.svg'/>
        </Menu.Item>
      </Menu>
    </Fragment>
  )

}


export default CollapsedContent;
