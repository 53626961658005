import {
    SET_POSTS, INITIAL_POSTS_FETCH, SET_SELECTED_POST
} from '../constants/action-types'

let initialState = {
    posts: null, 
    locationlessPosts: null,
    initialFetch: false,
    selectedPost: null
};

export default function posts(state=initialState, action) {
    switch (action.type) {
        case SET_SELECTED_POST: {
            return Object.assign({}, state, {
                selectedPost: action.selectedPost
            });
        }
        case SET_POSTS: {
            const posts = {
                type: "FeatureCollection",
                features: action.posts.features.filter(function(post){
                    return post.properties.address
                })
            }

            const locationlessPosts = {
                type: "FeatureCollection",
                features: action.posts.features.filter(function(post){
                    return !post.properties.address
                })
            }

            return Object.assign({}, state, {
                posts: posts,
                locationlessPosts: locationlessPosts
            });
        }
        case INITIAL_POSTS_FETCH: {
            return Object.assign({}, state, {
                initialFetch: true
            });
        }
        default:
            return state
    }
}
