import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { CloseOutlined, CheckOutlined, InstagramOutlined } from '@ant-design/icons';
import { setMapGrid } from "../../../actions/mapgrid";
import mapboxgl from 'mapbox-gl';
import { getVisits } from '../../../actions/visits';
import { getTravels } from '../../../actions/travels';
import { getTrips } from '../../../actions/trips';
import { setMiniMap } from '../../../actions/map';
import { getProfilesFriends } from '../../../actions/friends';
import { getMyProfile, createProfile, setSelectedProfile, updateProfile, setMyProfile } from "../../../actions/profile";
import { Avatar, List, Typography, Row, Col, Input, Button, Form, Upload, Modal, Comment, notification } from 'antd';
import { setSelectedTrip } from "../../../actions/trips";
import { bucketName, accessKeyId, secretAccessKey, region } from "../../../constants/config";
import { RightOutlined, DownOutlined, CarOutlined, BranchesOutlined, TrademarkOutlined, CameraOutlined } from '@ant-design/icons';
import S3FileUpload from 'react-s3';
import AddFriendButton from './addFriendButton'
import {
	API_URL
} from '../../../constants/config';
import axios from 'axios';
const { TextArea } = Input
const { Title } = Typography;

const Profile = () => {

	const mapgrid = useSelector(state => state.mapGrid.mapgrid);
	const points = useSelector(state => state.points.points);
	const posts = useSelector(state => state.posts.posts);
	const locationlessPosts = useSelector(state => state.posts.locationlessPosts);
	const selectedProfile = useSelector(state => state.profile.selectedProfile);
	const myProfile = useSelector(state => state.profile.myProfile);
	const token = useSelector(state => state.token.token);
	const selectedTrip = useSelector(state => state.trips.selectedTrip);
	const [selectedTravels, setSelectedTravels] = useState([]);
	const miniMapContainer = useRef(null);
	const [map, setMap] = useState(null);
	const [countries, setCountries] = useState(null);
	const [bucketlist, setBucketlist] = useState(null);
	const [friends, setFriends] = useState([]);
	const [trips, setTrips] = useState([]);
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	
	const {
		user
	} = useAuth0();

	const handleOk = (e) => {
		setVisible(false);
	};

	const handleCancel = (e) => {
		setVisible(false);
	};

	const bezier = (startCoord, endCoord, inverted) => {
		const midpoint = [
			(startCoord[0] + endCoord[0]) / 2,
			(startCoord[1] + endCoord[1]) / 2
		]
		const slope = (endCoord[1] - startCoord[1]) / (endCoord[0] - startCoord[0])
		const length = ((endCoord[0]-startCoord[0])**2 + (endCoord[1]-startCoord[1])**2)**(0.5)
		const orthogonalSlope = 1.0 / slope;
		const b = midpoint[1] - orthogonalSlope * midpoint[0] 
		const orthogonalLine = (x) => orthogonalSlope * x + b
		const distExt = (length > 50 && length < 150) ? 2 : 1
		const distFact = 2.0 * distExt
		const invertedFact = inverted ? -1 : 1;
		const othogonalPoint = [midpoint[0] + distFact * invertedFact, orthogonalLine(midpoint[0] + distFact * invertedFact)]
		const bz = function(t) {
			const a = (1-t)*(1-t);
			const b = 2*(1-t)*t;
			const c = t*t;
			const p0 = [a*startCoord[0], a*startCoord[1]]
			const p1 = [b*othogonalPoint[0], b*othogonalPoint[1]]
			const p2 = [c*endCoord[0], c*endCoord[1]]
			return [ p0[0] + p1[0] + p2[0], p0[1] + p1[1] + p2[1]]
	 	}
	 	const interpRate = 20
		const interpPoints = [...Array(interpRate+1).keys()].map(function(p){
			return p / interpRate;
		})
		const curvePoints = interpPoints.map(function(iP){
			return bz(iP);
		});
		return curvePoints;
	}

	useEffect(() => {
		if (!map || !map.isStyleLoaded()) {
			return;
		}
		const upcomingTrips = trips.filter(function(trip){
			return Date.parse(trip.startDate) > Date.now();
		})
		const upcomingTravelPromises = upcomingTrips.map(function(trip){
			return dispatch(getTravels(trip.trip_id, token))
		})
		Promise.all(upcomingTravelPromises).then(function(travels){
			const upcomingTravels = [];
			travels.forEach(function(travel){
				travel.data.travels.forEach(function(t){
					upcomingTravels.push(t);
				});
			});

			const cleanUpcomingTravels = upcomingTravels.map(function(travel){
				const newTravel = {}
				newTravel.startCoords = [parseFloat(travel.startCoords[1]), parseFloat(travel.startCoords[0])];
				newTravel.endCoords = [parseFloat(travel.endCoords[1]), parseFloat(travel.endCoords[0])];
				return newTravel;
			});

			const travelLineFeatures = cleanUpcomingTravels.map(function(travel, index){
				const coords = bezier(travel.startCoords, travel.endCoords, index%2)
				return {
					"type": "Feature",
					"properties": {
						index: index%5
					},
					"geometry": {
						"type": "LineString",
						"coordinates": coords
					}
				}
			});
			const travelPointFeatures = [];

			cleanUpcomingTravels.forEach(function(travel, index){
				travelPointFeatures.push({
					"type": "Feature",
					"properties": {},
					"geometry": {
						"type": "Point",
						"coordinates": travel.startCoords
					}
				});
				travelPointFeatures.push({
					"type": "Feature",
					"properties": {},
					"geometry": {
						"type": "Point",
						"coordinates": travel.endCoords
					}
				})
			});

			const travelLineFc = {
				"type": "FeatureCollection",
				"features": travelLineFeatures
			}
			const travelPointFc = {
				"type": "FeatureCollection",
				"features": travelPointFeatures
			}

			if(!map.getSource('travelLine')){
				map.addSource('travelLine', {
					'type': 'geojson',
					'data': travelLineFc
				});
			} else {
				map.getSource('travelLine').setData(travelLineFc)
			}

			if(!map.getSource('travelPoint')){
				map.addSource('travelPoint', {
					'type': 'geojson',
					'data': travelPointFc
				});
			} else {
				map.getSource('travelPoint').setData(travelPointFc)
			}

			if(!map.getLayer('travelLine')){
				map.addLayer({
					'id': 'travelLine',
					'type': 'line',
					'source': 'travelLine',
					'layout': {
						'line-join': 'round',
						'line-cap': 'round'
					},
					'paint': {
						'line-color': [
							'match',
							['get', 'index'],
							0,
							'#FF0000',
							1,
							'#8AEB3F',
							2,
							'#29A8EF',
							3,
							'#DD21BF',
							4,
							'#E9D04B',
							/* other */ '#ccc'
						],
						'line-width': 2,
						'line-dasharray': [2, 2]
					}
				});
			}

			if(!map.getLayer('travelPoint')){
				map.addLayer({
					'id': 'travelPoint',
					'type': 'circle',
					'source': 'travelPoint',
					'paint': {
						"circle-radius": 3,
			            "circle-color": "#FFFFFF",
			            "circle-opacity": 1.0,
			            "circle-stroke-width": 0
					}
				});
			}
		});
	// eslint-disable-next-line
	}, [trips, map])

	useEffect(() => {
		if(!token) {
			return;
		}
		const getProfile = function() {
			dispatch(getMyProfile(token)).then(function(profile){
				if (!profile.data.profile) {
					dispatch(createProfile({
						name: user.nickname,
						email: user.email,
						title: 'Nomad',
						phone: '(123) 456-7891',
						bio: 'My Bio!',
						background: 'https://nomad-app-images.s3.amazonaws.com/profile-background.png',
						interests: [],
						nickname: user.nickname,
						profile: '',
						instagramUrl: 'https://www.instagram.com/'
					}, token)).then(function(){
						getProfile();
					})
				} else {
					dispatch(setMyProfile(profile.data.profile));
					dispatch(setSelectedProfile(profile.data.profile));
				    form.setFieldsValue(profile.data.profile);
				}
			});
		}
		getProfile();
	// eslint-disable-next-line
	}, [token]);

	const doUpdateProfile = (values) => {
		dispatch(updateProfile({
			...selectedProfile,
			...values
		}, token)).then(function(data){
			if(data.data.status === 500) {
				notification.open({
				    message: data.data.message,
				});
			}
			else {
				dispatch(setSelectedProfile(data.data.profile));
				notification.open({
				    message: 'Profile Updated',
				});
			}
			
		})
	}

	const getFriendsList = async function(token) {
		dispatch(getProfilesFriends(selectedProfile.sub, token)).then(function(data){
			 setFriends(data.data.friends);
		});
	};

	const getTripsList = async function(token) {
		dispatch(getTrips(token, selectedProfile.sub)).then(function(data){
			 setTrips(data.data.trips);
		});
	}

	const selectTrip = (trip) => {
		dispatch(getTravels(trip.trip_id, token)).then(function(travels){
			setSelectedTravels(travels.data.travels);
		});
		dispatch(setSelectedTrip(trip));
	}

	useEffect(() => {
		if(!selectedProfile){
			dispatch(setSelectedProfile(myProfile));
		} else {
			if( !map ){
				return
			}
			form.setFieldsValue(selectedProfile);
			getFriendsList(token);
			getTripsList(token);

			dispatch(getVisits(token, selectedProfile.sub)).then(function(visits){
				setCountries(visits.data.visits.filter(function(visit){
					return !visit.bucketList;
				}));
				setBucketlist(visits.data.visits.filter(function(visit){
					return visit.bucketList;
				}));
				const countryPromises = visits.data.visits.filter(function(visit){
					return !visit.bucketList;
				}).map(function(visit){
					return axios.get(`${API_URL}/countries`, {
				  	  headers: {
					    'Authorization': `Bearer ${token}`
					  },
					  params: {
					  	name: visit.name.toLowerCase()
					  }
					});
				});
				const bucketlistPromises = visits.data.visits.filter(function(visit){
					return visit.bucketList;
				}).map(function(visit){
					return axios.get(`${API_URL}/countries`, {
				  	  headers: {
					    'Authorization': `Bearer ${token}`
					  },
					  params: {
					  	name: visit.name.toLowerCase()
					  }
					});
				})
				Promise.all(countryPromises).then(function(values){
					const features = values.map(function(value){
						return {
							"type": "Feature",
							"geometry": value.data.country.geometry,
						}
					});
					const fc = {
						"type": "FeatureCollection",
						"features": features
					}
					if(map.getSource('country')){
						map.getSource('country').setData(fc)
					} else {
						map.addSource('country', {
							'type': 'geojson',
							'data': fc
						});
					}
					if(!map.getLayer('country')){
						map.addLayer({
									'id': 'country',
									'type': 'fill',
									'source': 'country', // reference the data source
									'layout': {},
									'paint': {
										'fill-color': '#772F39', // blue color fill
										'fill-opacity': 0.5
									}
						});
					}
				});
				Promise.all(bucketlistPromises).then(function(values){
					const features = values.map(function(value){
						return {
							"type": "Feature",
							"geometry": value.data.country.geometry,
						}
					});
					const fc = {
						"type": "FeatureCollection",
						"features": features
					}
					if(map.getSource('bucketList')){
						map.getSource('bucketList').setData(fc)
					} else {
						map.addSource('bucketList', {
							'type': 'geojson',
							'data': fc
						});
					}
					if(!map.getLayer('bucketList')){
						map.addLayer({
									'id': 'bucketList',
									'type': 'fill',
									'source': 'bucketList', // reference the data source
									'layout': {},
									'paint': {
										'fill-color': '#FFFFFF', // blue color fill
										'fill-opacity': 0.35
									}
						});
					}

					map.resize()
				})
			});
		}
	// eslint-disable-next-line
	}, [selectedProfile]);

	useEffect(() => {
		const m = new mapboxgl.Map({
			container: miniMapContainer.current,
			style: 'mapbox://styles/mapbox/dark-v9',
			center: [5, 34],
			zoom: 1,
			renderWorldCopies: true,
			maxBounds: [
				[-720, -60], // Southwest coordinates
				[720, 80] // Northeast coordinates
			]
		});

		// disable map rotation using right click + drag
		m.dragRotate.disable();
		 
		// disable map rotation using touch rotation gesture
		m.touchZoomRotate.disableRotation();

		m.scrollZoom.disable();

		m.doubleClickZoom.disable();

		dispatch(setMiniMap(m))

		setMap(m);

	}, [dispatch]);

	const backgroundProps = {
	  name: 'file',
	  accept: "image/png, image/jpeg",
	  onChange(info) {
	  	const s3Config = {
			bucketName, 
			accessKeyId, 
			secretAccessKey,
			region
		}
	   const image_promise = S3FileUpload.uploadFile(info.file.originFileObj, s3Config);
	   image_promise.then(function(data){
	   	doUpdateProfile({
	   		...selectedProfile,
	   		background: data.location
	   	});
	   })
	  },
	};

	const profileProps = {
	  name: 'file',
	  accept: "image/png, image/jpeg",
	  onChange(info) {
	  	const s3Config = {
			bucketName, 
			accessKeyId, 
			secretAccessKey,
			region
		}
	   const image_promise = S3FileUpload.uploadFile(info.file.originFileObj, s3Config);
	   image_promise.then(function(data){
	   	doUpdateProfile({
	   		...selectedProfile,
	   		profile: data.location
	   	});
	   })
	  },
	};


	return (
		<div style={{ textAlign: 'left', width: '100%', overflowY: 'scroll', display: (mapgrid === 'profile' ? null : 'none')}}>
			<Button 
				style={{zIndex: 10000, marginLeft: '80%', marginTop: 100, position: 'fixed'}}
				size='large'
				icon={<CloseOutlined/>}
				onClick={() => {
					dispatch(setMapGrid('map'));
					dispatch(setSelectedProfile(null));
				}}
			/>
			 <div className="profile-background" {...backgroundProps}>
			 	<div style={{width: '100%', height: '100%', minHeight: 500}}  ref={miniMapContainer}></div>
		    </div>
		    {selectedProfile && (selectedProfile.sub === user.sub) ? <Upload className="profile-background" {...profileProps}>
			{ user ? <div class="container"><Avatar size={200} key={Date.now()} style={{marginLeft: '10%',  marginTop: -100, position: 'relative', backgroundColor: 'black'}} src={
				selectedProfile ? selectedProfile.profile || './nomad-logo-square.png' : './nomad-logo-square-black.png'
			} />
				<div class="overlay">
					<div class="text">Upload New<br/><CameraOutlined /></div>
				</div>
			</div> : null }
			</Upload> : null }
			{selectedProfile && (selectedProfile.sub !== user.sub) ? <div className="profile-background" {...profileProps}>
			{ user ? <div><Avatar size={200} key={Date.now()} style={{marginLeft: '10%',  marginTop: -100, position: 'relative', backgroundColor: 'black'}} src={
				selectedProfile ? selectedProfile.profile || './nomad-logo-square.png' : './nomad-logo-square-black.png'
			} /></div> : null }
			</div> : null }
			<Col>
				<Title level={5} className="points-title" > {points} Nomad Points {selectedProfile && (selectedProfile.sub !== user.sub) ? <AddFriendButton onFriendChange={getFriendsList} friend={selectedProfile}/> : null} <br/> <span onClick={() => setVisible(true)}>{friends.length} Followers</span></Title>
			</Col>
			<Form
			   	id="profileForm"
			    form={form}
			    onFinish={doUpdateProfile}
			    layout="vertical"
			   >
			{ selectedProfile ? <Row gutter={16} >
				 <Col className="gutter-row" xs={{span: 11, offset: 11}}  md={{span: 8, offset: 8}} lg={{span: 8, offset: 8}}>
					<Form.Item
				        name="name"
				        label="Name"
				        rules={[
				          selectedProfile.sub === user.sub ? {
				            required: true,
				          } : null
				        ]}
				      >
				        <Input disabled={selectedProfile.sub !== user.sub} placeholder="Name"/>
				      </Form.Item>
				</Col>
				<Col className="gutter-row" xs={{span: 20, offset: 2}} md={{span: 7, offset: 0}} lg={{span: 7, offset: 0}}>
					<Form.Item
				        name="title"
				        label="Title"
				        rules={[
				          selectedProfile.sub === user.sub ? {
				            required: true,
				          } : null
				        ]}
				      >
				        <Input disabled={selectedProfile.sub !== user.sub} placeholder="Title"/>
				    </Form.Item>
				</Col>
			</Row> : null }
			{ selectedProfile ? <Row gutter={16} style={{marginTop: 16}} >
				<Col className="gutter-row"  xs={{span: 20, offset: 2}} md={{span: 6, offset: 2}} lg={{span: 6, offset: 2}}>
					<Form.Item
				        name="phone"
				        label="Phone"
				        rules={[
				          selectedProfile.sub === user.sub ? {
				            required: true,
				          } : null
				        ]}
				      >
				        <Input disabled={selectedProfile.sub !== user.sub} placeholder="Phone"/>
				    </Form.Item>
				</Col>
				<Col className="gutter-row" xs={{span: 20, offset: 2}} md={{span: 8, offset: 0}} lg={{span: 8, offset: 0}}>
					<Form.Item
				        name="nickname"
				        label="Nickname"
				        rules={[
				          selectedProfile.sub === user.sub ? {
				            required: true,
				          } : null
				        ]}
				      >
				        <Input disabled={selectedProfile.sub !== user.sub} placeholder="Nickname"/>
				    </Form.Item>
				</Col>
				<Col className="gutter-row" xs={{span: 20, offset: 2}} md={{span: 7, offset: 0}} lg={{span: 7, offset: 0}}>
					{selectedProfile.sub !== user.sub ? <a href={selectedProfile.instagramUrl}> <Form.Item
				        name="instagramUrl"
				        label={<span> <InstagramOutlined/>Instagram URL</span>}
				        rules={[
				          selectedProfile.sub === user.sub ? {
				            required: true,
				          } : null
				        ]}
				      >
				        <Input disabled={selectedProfile.sub !== user.sub} placeholder="Instagram URL"/>
				    </Form.Item> </a> : null }
				    {selectedProfile.sub === user.sub ? <Form.Item
				        name="instagramUrl"
				        label={<span> <InstagramOutlined/>Instagram URL</span>}
				        rules={[{
				            type: "url",
				            message: "This field must be a valid url."
				        }]}
				      >
				       <Input disabled={selectedProfile.sub !== user.sub} placeholder="Instagram URL"/>
				    </Form.Item> : null }
				</Col>
			</Row> : null }
			{ selectedProfile ? <Row gutter={16} style={{marginTop: 16}} >
				<Col className="gutter-row" offset={2} span={21}>
					<Form.Item
				        name="bio"
				        label="Bio"
				        rules={[
				          selectedProfile.sub === user.sub ? {
				            required: true,
				          } : null
				        ]}
				      >
				        <TextArea disabled={selectedProfile.sub !== user.sub} rows={10} placeholder={"My Bio"}/>
				    </Form.Item>
				</Col>
			</Row> : null }
			{ selectedProfile ? <Row gutter={16} >
				<Col className="gutter-row" offset={2} span={21}>
					{ (selectedProfile.sub !== user.sub) ? null : <Button key="submit" form="profileForm" type="primary" onClick={form.submit}>
		              Update
		            </Button> }
	            </Col>
			</Row> : null }

			</Form>
			<Row>
				<Col offset={2} span={3} className="country-list">
					<div style={{maxHeight: 250}}>
						<strong>Visited List</strong>
						{ countries ? countries.slice(0,13).map(function(country){
							return <p key={country.name} style={{marginBottom: 0}}><CheckOutlined/>{country.name}</p>
						}) : null }
					</div>
				</Col>
				{ (countries && countries.length > 13) ? <Col span={3} className="country-list">
					<div style={{maxHeight: 250}}>
						{ countries ? countries.slice(13, 26).map(function(country){
							return <p key={country.name} style={{marginBottom: 0}}><CheckOutlined/>{country.name}</p>
						}) : null }
					</div>
				</Col> : null }
				{ (countries && countries.length > 26) ? <Col span={3} className="country-list">
					<div style={{maxHeight: 250}}>
						{ countries ? countries.slice(26, 39).map(function(country){
							return <p key={country.name} style={{marginBottom: 0}}><CheckOutlined/>{country.name}</p>
						}) : null }
					</div>
				</Col> : null }
				{ (countries && countries.length > 39) ? <Col span={3} className="country-list">
					<div style={{maxHeight: 250}}>
						{ countries ? countries.slice(39, 52).map(function(country){
							return <p key={country.name} style={{marginBottom: 0}}><CheckOutlined/>{country.name}</p>
						}) : null }
					</div>
				</Col> : null }
				{ (countries && countries.length > 52) ? <Col span={3} className="country-list">
					<div style={{maxHeight: 250}}>
						{ countries ? countries.slice(52, 65).map(function(country){
							return <p key={country.name} style={{marginBottom: 0}}><CheckOutlined/>{country.name}</p>
						}) : null }
					</div>
				</Col> : null }
				<Col span={3} className="country-list">
					<div style={{maxHeight: 250, overflowY: 'scroll'}}>
					<strong>Bucket List</strong>
						{ bucketlist ? bucketlist.map(function(country){
							return <p key={country.name} style={{marginBottom: 0}}><CheckOutlined/>{country.name}</p>
						}) : null }
					</div>
				</Col>
			</Row>
			<Row style={{marginTop: 20}}>
				<Col offset={2} span={20}>
					<Title level={3}> Trips </Title>
					{ 
						trips ? trips.map(function(trip, idx){
							return	<React.Fragment key={idx}>
										<div className="trip-card" onClick={() => {
												(selectedTrip.trip_id === trip.trip_id) ? (function(){
													dispatch(setSelectedTrip({}));
													setSelectedTravels([]);
												})() : selectTrip(trip)
											}
										}>
											<p style={{margin: 0}}>
												<strong>{trip.name}</strong> 
												<span style={{float:'right'}}>
													{selectedTrip.trip_id === trip.trip_id ? <DownOutlined /> : <RightOutlined />} 
												</span>
											</p>
											<p>{trip.destinations}</p>
										</div>
										{selectedTrip.trip_id === trip.trip_id ? <div>
											{selectedTravels ? selectedTravels.map(function(travel){
												return <div className="travel-card"> 
													<Row>
														<Col span={10}>
														<p style={{marginBottom: 0}}><strong>{travel.startDate.substring(0,10)} </strong></p>
														<span style={{fontSize: 10}}>{travel.startAddress.substring(0, 20)}</span>
														</Col>
														<Col span={2}>
														<p style={{marginBottom: 0}}> <strong> - </strong></p>
														</Col>
														<Col span={10}>
														<p style={{marginBottom: 0}}><strong>{travel.endDate.substring(0,10)} </strong></p>
														<span style={{fontSize: 10}}>{travel.endAddress.substring(0, 20)}</span>
														</Col>
														<Col span={2} style={{color: 'white'}}>
															{travel.mode === 'Flight' ? <img alt="plane" style={{color: 'white'}} src="./plane-white.svg"/> : null}
															{travel.mode === 'Car' ? <CarOutlined /> : null}
															{travel.mode === 'Bus' ? <BranchesOutlined /> : null}
															{travel.mode === 'Train' ? <TrademarkOutlined /> : null}

														</Col>
													</Row>
												</div>
											}) : null}
										</div> : null }
									</React.Fragment>
						}) : null
					} 
				</Col>
			</Row>
			<Row style={{paddingBottom: 80, marginTop: 20}}>
				<Col offset={2} span={20}>
					<Title level={3}> Posts </Title>
					{
					       (posts && selectedProfile) ? (posts.features.concat(locationlessPosts.features)).filter(function(post){
					       		return post.properties.public && (selectedProfile.sub === post.properties.sub)
					       }).map(function(post, idx){
					      		return <Comment
					      				  style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5}}
									      author={
									      	<Col>
										      	<p style={{color: 'white', marginBottom: 0}}>
										      		<strong style={{fontSize: 16, letterSpacing: 3}}>{post.properties.title}</strong> <br/>
										      		<span style={{color: '#797979'}}>post &#183; {post.properties.name} </span>
										        </p>

									        </Col>
									  	  }
									      content={
									        <p style={{color: 'white'}}>
									          {post.properties.content} <br/>
									        </p>
									      }
									      key={idx}
									    />
					      	}) : null
					}
				</Col>
			</Row>
			<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						Followers
			  		</div>
				}
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button key="back" onClick={handleCancel}>
		              Close
		            </Button>
		        ]}
		   >
		   	<List
			    itemLayout="horizontal"
			    dataSource={friends}
			    renderItem={item => (
			      <List.Item
			      	style={{backgroundColor: '#363639', marginTop: 8, paddingLeft: 12, paddingRight: 8, borderRadius: 5, color: 'white'}}
			      >
			        <List.Item.Meta
			          avatar={<Avatar src={item.profile || './nomad-logo-square.png'} />}
			          title={item.name}
			          key={item.name}
			          description="Nomad Member"
			          style={{color: 'white'}}
			        />
			      </List.Item>
			    )}
			  />
		   </Modal>
		</div>
	)
}

export default Profile;