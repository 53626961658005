import {
    ADD_COMMENTS, REMOVE_COMMENT, UPDATE_COMMENT
} from '../constants/action-types'

let initialState = {
    comments: []
};

export default function comments(state=initialState, action) {
    switch (action.type) {
        case UPDATE_COMMENT: {
            return Object.assign({}, state, {
                comments: state.comments.map(function(comment){
                    if(comment.reply_id === action.comment.reply_id){
                        return action.comment
                    }
                    return comment
                })
            });
        }
        case ADD_COMMENTS: {
            const existingComments = state.comments.map(function(comment){
                return comment.reply_id;
            });
            const newComments = action.comments.filter(function(comment){
                return existingComments.indexOf(comment.reply_id) === -1;
            });
            return Object.assign({}, state, {
                comments: state.comments.concat(newComments)
            });
        }
        case REMOVE_COMMENT: {
            const filteredComments = state.comments.filter(function(comment){
                return comment.reply_id !== action.reply_id
            });
            return Object.assign({}, state, {
                comments: filteredComments
            });
        }
        default:
            return state
    }
}
