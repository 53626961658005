import {
    ADD_DISCUSSIONS, SET_SELECTED_DISCUSSION
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setDiscussions(discussions) {
	return {
		type: ADD_DISCUSSIONS,
		discussions: discussions
	};
}

export function setSelectedDiscussion(discussion) {
	return {
		type: SET_SELECTED_DISCUSSION,
		selectedDiscussion: discussion
	};
}

export function createDiscussion(discussion, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/discussions`, discussion, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}


export function getDiscussions(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/discussions`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(setDiscussions(data.data.discussions));
		});
	}
}

export function deleteDiscussion(discussion_id, token) {
	return function(dispatch, getState) {
		return axios.delete(`${API_URL}/discussions`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	discussion_id: discussion_id
		  }
		});
	}
}