import React, { useState } from "react";
import { Modal, Button, Form, Input } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import { setModal } from "../../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";
import { createReservation } from "../../../../actions/reservations";

const FeedbackModal = (props) => {

	const {
		user
	} = useAuth0();
	const modal = useSelector(state => state.modals.modalName);
	const token = useSelector(state => state.token.token);
	const [form] = Form.useForm();

	const locationTypes = [
		'NOMAD location', 'NOMAD partner location', 'NOMAD member location'
	];

	const defaultValues = {
        planet: 'Earth',
        costRange: [20, 50],
        rating: 5,
        locationType: locationTypes[0]
    }

    form.setFieldsValue(defaultValues)

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleOk = (e) => {
		dispatch(setModal(null));
	};

	const handleCancel = (e) => {
		dispatch(setModal(null));
	};

	const layout = {
	  labelCol: {
	    span: 8,
	  },
	  wrapperCol: {
	    span: 16,
	  },
	};

	const submit = (values) => {
		const {
			note
		} = values;
		dispatch(setModal(null));
		const feedback = {
			note,
			startDate: '',
			endDate: '',
			location: '',
			requestingUser: user.email
		}
		setLoading(true);
		dispatch(createReservation(feedback, token)).then(function(){
			setLoading(false);
		});
	}

    return (
    	<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						New Feedback
			  		</div>
				}
				visible={modal === 'feedback'}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button disabled={loading} key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button disabled={loading} key="submit" form="newPropertyForm" type="primary" onClick={form.submit}>
		              Save
		            </Button>
		        ]}
		   >
		   <Form
		   	{...layout}
		   	id="newFeedbackForm"
		   	initialValues={defaultValues}
		    form={form}
		    onFinish={submit}
		   >
		      <Form.Item
		        label="Note"
		        name="note"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Input.TextArea rows={6}/>
		      </Form.Item>
			</Form>
		 </Modal>
    )
}


export default FeedbackModal;