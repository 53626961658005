import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { getPoints } from "../../../actions/points";

const UserData = () => {
	const {
	    user
	} = useAuth0();

	const points = useSelector(state => state.points.points);
	const myProfile = useSelector(state => state.profile.myProfile);
	const token = useSelector(state => state.token.token);
	const dispatch = useDispatch()

	useEffect(() => {
		if(!token){
			return
		}
		setInterval(function(){
			dispatch(getPoints(token));
		}, 30000)
	// eslint-disable-next-line
	}, [token]);

	return(
		<React.Fragment>
			<div style={{lineHeight: '16px'}} >
				<br/>
					<strong style={{fontSize: 14}}>{myProfile ? myProfile.name.toUpperCase() : user.name.toUpperCase()}</strong>
				<br/>
				<p style={{fontSize: 8}}>
					NOMAD POINTS<br/>
					<strong style={{fontSize: 20}}>{points}</strong>
				</p>
			</div>
		</React.Fragment>
	)
}

export default UserData;