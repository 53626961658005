import React from "react";
import { Carousel, Rate, Row, Col, Empty } from 'antd';

const LocationPopUp = (props) => {

	const images = props.feature.properties.images.replace('[', '').replace(']', '').replaceAll('"','').split(',');

    return (
    	<div onClick={props.handler} style={{maxWidth: '250px'}}>
   			<Row align="middle">
		      <Col span={12}>
		      	<p style={{margin: 0}}><strong>{props.feature.properties.name.toUpperCase()}</strong></p>
		      	{props.feature.properties.name}
		      </Col>
		      <Col span={12}>
		      	<Rate style={{fontSize: '12px'}} allowHalf disabled={true} defaultValue={props.feature.properties.rating} />
                {props.feature.properties.locationType}
		      </Col>
		    </Row>
       		{ images.length ? <Carousel>
       			 <div>
    				<h3 style={{
    					width: '100%', 
    					height: '150px',
    					backgroundImage: 'url("'+ images[0] + '")',
    					backgroundSize: 'cover'
    				}}>
    				</h3>
    			</div>
    			<div>
    				<h3 style={{
    					width: '100%', 
    					height: '150px',
    					backgroundImage: 'url("'+ images[1] + '")',
    					backgroundSize: 'cover'
    				}}>
    				</h3>
    			</div> 
       		</Carousel> : null }
            {
                !images.length ? <Empty description={false}/> : null
            }
   		</div>
    )
}


export default LocationPopUp;