import {
    ADD_GUIDES
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function addGuides(guides) {
	return {
		type: ADD_GUIDES,
		guides: guides
	};
}

export function createGuide(guide, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/guides`, guide, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}


export function getGuides(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/guides`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			var fc = {
			  "type": "FeatureCollection",
			  "features": data.data.guides
			};
			dispatch(addGuides(fc));
		});
	}
}