import {
    SET_TRIGGER_STATUS
} from '../constants/action-types'

let initialState = { 
    triggerStatus: false
};

export default function trigger(state=initialState, action) {
    switch (action.type) {
        case SET_TRIGGER_STATUS: {
            return Object.assign({}, state, {
                triggerStatus: action.triggerStatus
            });
        }
        default:
            return state
    }
}
