import {
    SET_USER_LOCATIONS
} from '../constants/action-types'

let initialState = {userLocations: null};

export default function userLocations(state=initialState, action) {
    switch (action.type) {
        case SET_USER_LOCATIONS: {
            return Object.assign({}, state, {
                userLocations: action.userLocations
            });
        }
        default:
            return state
    }
}
