import {
    ADD_DISCUSSIONS, SET_SELECTED_DISCUSSION
} from '../constants/action-types'

let initialState = {
    discussions: [],
    selectedDiscussion: null
};

export default function discussions(state=initialState, action) {
    switch (action.type) {
        case SET_SELECTED_DISCUSSION: {
            return Object.assign({}, state, {
                selectedDiscussion: action.selectedDiscussion
            });
        }
        case ADD_DISCUSSIONS: {
            return Object.assign({}, state, {
                discussions: action.discussions
            });
        }
        default:
            return state
    }
}
