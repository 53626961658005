import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from "./serviceWorker";
import {Provider} from 'react-redux'
import rootReducer from './reducers'
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json";
import history from "./utils/history";

const middleware =[ thunkMiddleware, createLogger() ]

export const store = createStore(rootReducer, composeWithDevTools(
	applyMiddleware(...middleware)
));

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};

ReactDOM.render(
	<Auth0Provider
		domain={config.domain}
		clientId={config.clientId}
		audience={config.audience}
		redirectUri={window.location.origin}
		onRedirectCallback={onRedirectCallback}
		useRefreshTokens={true}
		display={"popup"}
		cacheLocation="localstorage"
	>
		<Provider store={store}>
			<App />
		</Provider>
	</Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
