import React, { useState, useEffect } from "react";
import { Modal, Button, Form, DatePicker } from 'antd';
import { setModal } from "../../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";
import { createStay } from "../../../../actions/stays";
import LocationSearch from "./locationSearch"
const { RangePicker } = DatePicker;


const StayModal = (props) => {
	const modal = useSelector(state => state.modals.modalName);
	const token = useSelector(state => state.token.token);
	const [address, setAddress] = useState(null);
	const [coords, setCoords] = useState(null);
	const [range, setRange] = useState(null)
	const [form] = Form.useForm();

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleOk = (e) => {
		dispatch(setModal(null));
	};

	const handleCancel = (e) => {
		dispatch(setModal(null));
	};
	useEffect(() => {
		
		// eslint-disable-next-line
	}, [address, coords, range])

	const addressHandler = (address, coords) => {
		setCoords(coords);
		setAddress(address);
	}

	const layout = {
	  labelCol: {
	    span: 8,
	  },
	  wrapperCol: {
	    span: 16,
	  },
	};

	const submit = (values) => {
		const {
			dates
		} = values;
		setLoading(false);
		dispatch(setModal(null));
		const stay = {
			startDate: dates[0],
			endDate: dates[1],
			address,
			coords,
			trip_id: props.selectedTrip.trip_id
		}
		dispatch(createStay(stay, token)).then(function(stay){
			props.onNewStay(stay);
		});
	}

    return (
    	<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						Stay Builder
			  		</div>
				}
				visible={modal === 'stay'}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button disabled={loading} key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button disabled={loading} key="submit" form="newPropertyForm" type="primary" onClick={form.submit}>
		              Next
		            </Button>
		        ]}
		   >
		   <Form
		   	{...layout}
		   	id="newPostForm"
		    form={form}
		    onFinish={submit}
		   >
			  <Form.Item
		        label="Where will you stay?"
		        name="address"
		      >
		      	<LocationSearch setAddress={addressHandler}/>
		      </Form.Item>
		      <Form.Item
		        label="Dates"
		        name="dates"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <RangePicker onChange={setRange} />
		      </Form.Item>
			</Form>
		 </Modal>
    )
}


export default StayModal;