import {
    SET_MAP_GRID
} from '../constants/action-types'

let initialState = {mapgrid: 'map'};

export default function mapGrid(state=initialState, action) {
    switch (action.type) {
        case SET_MAP_GRID: {
            return Object.assign({}, state, {
                mapgrid: action.mapgrid
            });
        }
        default:
            return state
    }
}
