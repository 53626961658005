import {
    SET_VISITS, INITIAL_VISITS_FETCH
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function initialVisitsFetch() {
	return {
		type: INITIAL_VISITS_FETCH
	}
}

export function setVisits(visits) {
	return {
		type: SET_VISITS,
		visits: visits
	};
}

export function getVisits(token, sub) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/visits`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	sub: sub
		  }
		});
	}
}

export function createVisit(visit, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/visits`, visit, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function deleteVisit(visit_id, token) {
	return function(dispatch, getState) {
		return axios.delete(`${API_URL}/visits`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	visit_id: visit_id
		  }
		});
	}
}