import axios from 'axios';

import {
	API_URL
} from '../constants/config';

export function getFlights(startIATA, endIATA, date, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/flights`, {
			data: {
				"slices" : [
			      {
			        "origin": startIATA,
			        "destination": endIATA,
			        "departure_date": date
			      }
			    ],
			    "passengers": [{ "type": "adult" }],
			    "cabin_class": "economy"
			}
		},
		{
			headers: {
			  	'Authorization': `Bearer ${token}`,
			},
		})
	}
}
