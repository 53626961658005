import React from "react";
import { Button, Menu } from 'antd';
import UserNotifications from '../../navbar/userNotifications/userNotifications'

const Notifications = () => {

	return (
		<Button
			type="primary" 
			shape="circle"
			icon={<Menu mode="vertical" style={{borderRadius:50, border: 'none'}}><UserNotifications/></Menu>}
			className="hide-gt-sm"
			size='large'
			style={{
				position: 'fixed', left: '24px', 
				bottom: '24px', zIndex: 1000, boxShadow: '0px 0px 15px black'}}
		>
		</Button>
	)
}

export default Notifications;