import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { Avatar } from 'antd';

const UserAvatar = () => {
	const {
	    user
	} = useAuth0();

	const myProfile = useSelector(state => state.profile.myProfile);

	return(
			user.picture ? 
					<Avatar size={56} style={{marginTop: 12}} src={(myProfile && myProfile.profile) ? myProfile.profile : './nomad-logo-square.png'} /> : 
					<Avatar size={56} style={{marginTop: 12}} icon={<UserOutlined />} />
		
	)
}

export default UserAvatar;