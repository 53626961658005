import {
   SET_COUNTRIES
} from '../constants/action-types';

export function setCountries(countries) {
	return {
		type: SET_COUNTRIES,
		countries: countries
	};
}
