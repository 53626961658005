import {
	SET_POSTS, INITIAL_POSTS_FETCH, SET_SELECTED_POST
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setSelectedPost(selectedPost) {
	return {
		type: SET_SELECTED_POST,
		selectedPost: selectedPost
	};
}

export function initialPostsFetch() {
	return {
		type: INITIAL_POSTS_FETCH
	}
}

export function setPosts(posts) {
	return {
		type: SET_POSTS,
		posts: posts
	};
}

export function getPosts(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/posts`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			var fc = {
			  "type": "FeatureCollection",
			  "features": data.data.posts
			};
			dispatch(setPosts(fc));
		});
	}
}

export function createPost(post, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/posts`, post, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function deletePost(post_id, token) {
	return function(dispatch, getState) {
		return axios.delete(`${API_URL}/posts`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	post_id: post_id
		  }
		});
	}
}