// Conversations
export const ADD_CONVERSATIONS = 'ADD_CONVERSATIONS'
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'

// Self
export const FETCH_SELF = 'FETCH_SELF'

// Countries
export const SET_COUNTRIES = 'SET_COUNTRIES'

// Trips
export const GET_TRIPS = 'GET_TRIPS'
export const SET_TRIPS = 'SET_TRIPS'
export const CREATE_TRIP = 'CREATE_TRIP'
export const SET_SELECTED_TRIP = 'SET_SELECTED_TRIP'

// Sidebar
export const SET_SIDEBAR_TAB = 'SET_SIDEBAR_TAB'
export const SET_SIDEBAR_STATUS = 'SET_SIDEBAR_STATUS'

// Locations
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const CREATE_LOCATION = 'CREATE_LOCATION'
export const INITIAL_LOCATIONS_FETCH = 'INITIAL_LOCATIONS_FETCH'

// Permissions
export const ADD_PERMISSIONS = 'ADD_PERMISSIONS'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'

// Map
export const SET_ON_CLICK = 'SET_ON_CLICK'
export const SET_MAP = 'SET_MAP'
export const SET_MINI_MAP = 'SET_MINI_MAP'
export const RAISE_RESIZE_FLAG = 'RAISE_RESIZE_FLAG'
export const LOWER_RESIZE_FLAG = 'LOWER_RESIZE_FLAG'
export const SET_GEOCODER = 'SET_GEOCODER'
export const SET_FEATURES_IN_VIEW = 'SET_FEATURES_IN_VIEW'

// Reviews
export const ADD_REVIEWS = 'ADD_REVIEWS'
export const CREATE_REVIEW = 'CREATE_REVIEW'

// Guides
export const ADD_GUIDES = 'ADD_GUIDES'

// Deals
export const SET_DEALS = 'SET_DEALS'

// Visits
export const INITIAL_VISITS_FETCH = 'INITIAL_VISITS_FETCH'
export const SET_VISITS = 'SET_VISITS'

// Modals
export const SET_MODAL = 'SET_MODAL'

// Points
export const ADD_POINTS = 'ADD_POINTS'
export const GET_POINTS = 'GET_POINTS'

// Reservations
export const SET_RESERVATIONS = 'SET_RESERVATIONS'

// Gigs
export const SET_SELECTED_GIG = 'SET_SELECTED_GIG'
export const SET_GIGS = 'SET_GIGS'
export const CREATE_GIG = 'CREATE_GIG'
export const INITIAL_GIGS_FETCH = 'INITIAL_GIGS_FETCH'

// Posts
export const SET_SELECTED_POST = 'SET_SELECTED_POST'
export const INITIAL_POSTS_FETCH = 'INITIAL_POSTS_FETCH'
export const SET_POSTS = 'SET_POSTS'

// Comments
export const ADD_COMMENTS = 'ADD_COMMENTS'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'

// Trigger
export const SET_TRIGGER_STATUS = 'SET_TRIGGER_STATUS'

// Friends
export const SET_FRIENDS = 'SET_FRIENDS'

// Notifications
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

// Discussions
export const ADD_DISCUSSIONS = 'ADD_DISCUSSIONS'
export const SET_SELECTED_DISCUSSION = 'SET_SELECTED_DISCUSSION'

// Profile
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE'
export const GET_PROFILE = 'GET_PROFILE'
export const SET_PROFILES = 'SET_PROFILES'
export const SET_MY_PROFILE = 'SET_MY_PROFILE'

// MapGrid
export const SET_MAP_GRID = 'SET_MAP_GRID'

// User Locations
export const SET_USER_LOCATIONS = 'SET_USER_LOCATIONS'

// Token
export const SET_TOKEN = 'SET_TOKEN'