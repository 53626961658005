import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function getTravels(trip_id, token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/travels`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	trip_id: trip_id
		  }
		});
	}
}

export function createTravel(travel, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/travels`, travel, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}