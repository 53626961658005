import {
    SET_SELECTED_LOCATION, SET_LOCATIONS, INITIAL_LOCATIONS_FETCH
} from '../constants/action-types'

let initialState = {
    locations: null, 
    initialFetch: false,
    selectedLocation: null
};

export default function locations(state=initialState, action) {
    switch (action.type) {
        case SET_SELECTED_LOCATION: {
            return Object.assign({}, state, {
                selectedLocation: action.selectedLocation
            });
        }
        case SET_LOCATIONS: {
            return Object.assign({}, state, {
                locations: action.locations
            });
        }
        case INITIAL_LOCATIONS_FETCH: {
            return Object.assign({}, state, {
                initialFetch: true
            });
        }
        default:
            return state
    }
}
