import React, { useEffect, useState } from "react";
import { Row } from 'antd';
import { CloudOutlined } from '@ant-design/icons';

const API_KEY = '6b5b783fa1594e8a6f23d8a26ae5e793'

const UserTemperature = () => {

	const [weather, setWeather] = useState();
	const [temperature, setTemperature] = useState();

	const getWeather = async (latitude, longitude) => {
	  const api_call = await
	  fetch(`//api.openweathermap.org/data/2.5/weather?
		lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`);
	  const data = await api_call.json();
	  setWeather(data.weather[0].main);
	  setTemperature(data.main.temp); 
	}

	useEffect(() => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(function(position) {
				const lat = position.coords.latitude;
				const lon = position.coords.longitude;
				getWeather(lat, lon)
		    }, function(err){
		    	console.log(err)
		    });
	    } else {
	      console.log("Not Available");
	    }
	});

	var icon;
	if (weather === 'Clouds') {
      icon = <CloudOutlined style={{fontSize: 30}}/>;
    } else {
      icon = <CloudOutlined style={{fontSize: 30}}/>;
    }

	return(
		weather ? <Row>
			<p style={{letterSpacing: 3, marginBottom: 0, marginTop: 5, lineHeight: '28px', textAlign: 'center'}}>
				<span style={{fontSize: 12, fontWeight: 'bold'}}>TODAY</span> <br/>
				<strong style={{fontSize: 24}}>{temperature ? temperature.toFixed(0) : null}&#176;</strong>
			</p>
			<p style={{marginBottom: 0, marginTop: 5, lineHeight: '28px', textAlign: 'center', marginLeft: 20}}>
				<span style={{fontSize: 12, fontWeight: 'bold'}}>{(new Date()).getMonth()}/{(new Date()).getDate()}</span> <br/>
				{icon}
			</p>
		</Row> : null
	)
}

export default UserTemperature;