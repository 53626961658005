import React from "react";
import { Image } from 'antd';

const UserLocationPopUp = (props) => {
    return (
    	<div onClick={props.handler} style={{maxWidth: '50px'}}>
            <Image preview={false} src={props.selectProfile.profile || './nomad-logo-square-black.png'}/>
   		</div>
    )
}


export default UserLocationPopUp;