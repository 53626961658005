import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Badge, Avatar } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import useWindowDimensions from '../../../utils/useWindowDimensions.js'
import { markNotificationsRead, setNotifications } from '../../../actions/notifications.js'
const { SubMenu } = Menu;

const UserNotifications = (props) => {

	const notifications = useSelector(state => state.notifications.notifications);
	const token = useSelector(state => state.token.token);
	const dispatch = useDispatch();
	const { width } = useWindowDimensions();

	const onTitleClick = function () {
		dispatch(setNotifications(notifications.map(function(notification){
			notification.viewed = true;
			return notification;
		})));
		dispatch(markNotificationsRead(notifications.map(function(notification){
			return notification.notification_id
		}), token));
	}

	return(
		<SubMenu popupClassName="notifications-list" onMouseEnter={onTitleClick} onTitleClick={onTitleClick} popupOffset={[width > 600 ? 0 : 250, width > 600 ? 0 : -200]} {...props} key="notifications" style={{marginLeft: 'auto', color: 'white', letterSpacing: '2px', marginTop: width > 767 ? 12 : 6}} 
			icon={<Badge count={notifications.filter(function(notification){
				return !notification.viewed
			}).length} size="small">
		      		<BellOutlined style={{color: 'white', fontSize: 20, marginRight: 0, marginLeft: 2}}/>
		    	  </Badge>} >
		   	{
		   		notifications.length ? notifications.map(function(notification, idx){
		   			return <Menu.Item key={idx} style={{color: 'white'}}>
		   				<Avatar src={notification.icon}/> 
		   				<span>{notification.content}</span>

		   			</Menu.Item>
		   		}) : null
		   	}
		   	{
		   		notifications.length ? null : <Menu.Item style={{color: 'white'}}  >No New Notifications</Menu.Item>
		   	}
	    </SubMenu>
	)
}

export default UserNotifications;