import {
   SET_NOTIFICATIONS
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';

export function setNotifications(notifications) {
	return {
		type: SET_NOTIFICATIONS,
		notifications: notifications
	};
}

export function getNotifications(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/notifications`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			dispatch(setNotifications(data.data.notifications));
		});
	}
}

export function markNotificationsRead(notifications, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/notifications/mark_read`, notifications, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(){
			dispatch(getNotifications(token));
		});
	}
}