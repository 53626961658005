import {
    SET_NOTIFICATIONS
} from '../constants/action-types'

let initialState = {
    notifications: []
};

export default function notifications(state=initialState, action) {
    switch (action.type) {
        case SET_NOTIFICATIONS: {
            return Object.assign({}, state, {
                notifications: action.notifications
            });
        }
        default:
            return state
    }
}
