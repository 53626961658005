import React from "react";
import { Row, Col } from 'antd';

const TripLocationPopUp = (props) => {

    return (
    	<div onClick={props.handler} >
            <Row>
                <span style={{marginRight: 18}}>{props.startAddress} - {props.endAddress}</span> 
            </Row>
            <Row>
                <Col span={11}>
                    <strong>{props.startDate.slice(0,10)} </strong>
                </Col> 
                <Col span={2}>
                    - 
                </Col>
                <Col span={11}>
                    <strong>{props.endDate.slice(0,10)}</strong> 
                </Col>
            </Row>
   		</div>
    )
}


export default TripLocationPopUp;