import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Modal, Row, Col, Image } from 'antd';
import './navbar.css'
import { useSelector, useDispatch } from "react-redux";
import UserAvatar from './userAvatar/userAvatar.js'
import UserData from './userData/userData.js'
import UserLocation from './userLocation/userLocation.js'
import UserTemperature from './userTemperature/userTemperature.js'
import UserNotifications from './userNotifications/userNotifications.js'
import { setMapGrid } from "../../actions/mapgrid";
import { setSidebarStatus } from "../../actions/sidebar";
import { LogoutOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
const Navbar = () => {
	const {
		isLoading,
	    isAuthenticated,
	    loginWithRedirect,
	    logout
	} = useAuth0();

	if(!isLoading && !isAuthenticated){
		loginWithRedirect()
	}
	
	const miniMap = useSelector(state => state.map.miniMap);
	const guides = useSelector(state => state.guides.guides);
	const dispatch = useDispatch();

	const logoutWithRedirect = () =>
	    logout({
	      returnTo: window.location.origin,
	    });

	const [visible, setVisible] = useState(false);

	const viewProfile = () => {
		dispatch(setSidebarStatus(true))
		dispatch(setMapGrid('profile'))
		setTimeout(function(){
			miniMap.resize();
		}, 50)
	};

    const handleOk = () => {
    	setVisible(false);
    };

    const handleCancel = () => {
    	setVisible(false);
    };

	return(
		<Menu theme="dark" id="topbar" mode="horizontal" defaultSelectedKeys={['1']} style={{display: 'flex' }} >
			{!isAuthenticated && 
				<Menu.Item onClick={() => loginWithRedirect()}>
						login
				</Menu.Item>
			}
			{isAuthenticated && 
				<React.Fragment>
					<a target="_blank" rel="noreferrer" href="https://nomadnation.co/"><img className="hide-md" style={{height: '70px', float: 'left', marginTop: '6px'}} src='./NomadNations.svg' alt="Nomad Logo"/></a>
				    <Menu.Item key="5" className="hide-md" style={{float: 'left', color: 'white', letterSpacing: '2px', fontSize: '20px'}}>
						<UserTemperature/>
					</Menu.Item>
					<Menu.Item className="hide-md" style={{padding: 0, marginTop:10, marginBottom:10, borderLeft: '1px solid white'}}></Menu.Item>
				    <Menu.Item className="hide-sm" style={{float: 'left', marginTop: 4}}>
						<img className="hide-sm" alt="location" style={{width: '55px', height: '55px'}} src='../../location.png'/> 
					</Menu.Item>
					<Menu.Item className="hide-sm" key="6" style={{lineHeight: '17px', float: 'left', textAlign: 'left', padding: 0, color: 'white', letterSpacing: '2px'}}>
						<UserLocation/>
					</Menu.Item>
					<UserNotifications className="hide-sm" style={{float: 'right', marginLeft: 'auto'}}/>
					<SubMenu key="avatar" icon={<UserAvatar/>} >
				        <Menu.Item key="1" onClick={logoutWithRedirect}><LogoutOutlined /> Logout</Menu.Item>
				        <Menu.Item key="2"><a href="https://buy.stripe.com/3cs9DD2YteQg5eEeUV"><StarOutlined /> Premium Content</a></Menu.Item>
				        <Menu.Item key="3" onClick={viewProfile}><UserOutlined />My Profile</Menu.Item>
				    </SubMenu>
				    <SubMenu key="userdata" icon={<UserData/>} >
				        <Menu.Item key="1" onClick={logoutWithRedirect}><LogoutOutlined /> Logout</Menu.Item>
				        <Menu.Item key="2"><a href="https://buy.stripe.com/3cs9DD2YteQg5eEeUV"><StarOutlined /> Premium Content</a></Menu.Item>
				        <Menu.Item key="3" onClick={viewProfile}><UserOutlined />My Profile</Menu.Item>
				    </SubMenu>
					<Modal title="Nomad Guides" visible={visible} onOk={handleOk} onCancel={handleCancel}>
						<Row gutter={[16, 16]}>
						{guides ? 
							guides.features.map((guide, idx) => {
								return <Col key={idx} span={12} className="guide">
									<a download={guide.name + '.pdf'} rel="noreferrer" target="_blank" href={"https://nomad-guides.s3.amazonaws.com/" + guide.name + ".pdf"}> 
										<Image
									      width={230}
									      src={"https://nomad-guides.s3.amazonaws.com/" + guide.name + ".png"}
									    /> 
								    </a>
								</Col>
							}) : null
						}
						</Row>
					</Modal>
				</React.Fragment>
			}

		</Menu>
	)
}

export default Navbar;