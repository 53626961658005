import {
    ADD_CONVERSATIONS
} from '../constants/action-types'

let initialState = {conversations: []};

export default function conversations(state=initialState, action) {
    switch (action.type) {
        case ADD_CONVERSATIONS: {
            return Object.assign({}, state, {
                conversations: action.conversations
            });
        }
        default:
            return state
    }
}
