import { combineReducers } from 'redux'
import conversations from './conversations'
import sidebar from './sidebar'
import locations from './locations'
import permissions from './permissions'
import reviews from './reviews'
import guides from './guides'
import map from './map'
import modals from './modals'
import gigs from './gigs'
import posts from './posts'
import visits from './visits'
import deals from './deals'
import comments from './comments'
import discussions from './discussions'
import mapGrid from './mapgrid'
import points from './points'
import trigger from './trigger'
import profile from './profile'
import friends from './friends'
import trips from './trips'
import notifications from './notifications'
import userLocations from './userLocations'
import countries from './countries'
import token from './token'

const appReducer = combineReducers({
	conversations,
	sidebar,
	locations,
	permissions,
	map, 
	reviews,
	guides,
	comments,
	modals,
	gigs,
	deals,
	posts,
	visits,
	discussions,
	points,
	mapGrid,
	trigger,
	profile,
	friends,
	trips,
	notifications,
	userLocations,
	countries,
	token
});

const rootReducer = (state, action) => {
  if(action.type === 'REFRESH'){
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;