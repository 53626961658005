import React, { useState } from "react";
import { Modal, Button, Form, Input, DatePicker } from 'antd';
import { setModal } from "../../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";
import { createTrip, setSelectedTrip, getTrips, setTrips } from "../../../../actions/trips";
import { setSidebarTab, setSidebarStatus} from "../../../../actions/sidebar";
import { setTriggerStatus } from "../../../../actions/trigger";
import useWindowDimensions from '../../../../utils/useWindowDimensions.js'
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const TripModal = (props) => {
	const modal = useSelector(state => state.modals.modalName);
	const token = useSelector(state => state.token.token);
	const { width } = useWindowDimensions();
	const [form] = Form.useForm();

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleOk = (e) => {
		dispatch(setModal(null));
	};

	const handleCancel = (e) => {
		dispatch(setModal(null));
	};

	const layout = {
	  labelCol: {
	    span: 8,
	  },
	  wrapperCol: {
	    span: 16,
	  },
	};

	const submit = (values) => {
		const {
			name,
			destinations,
			dates
		} = values;
		setLoading(false);
		dispatch(setModal(null));
		const trip = {
			name,
			startDate: dates[0],
			endDate: dates[1],
			destinations
		}
		dispatch(createTrip(trip, token)).then(function(trip){
			dispatch(setSelectedTrip(trip.data.trip, token));
			dispatch(getTrips(token)).then(function(data){
				dispatch(setTrips(data.data.trips));
			});
			if (width > 780) {
				dispatch(setSidebarStatus(false));
			} else {
				dispatch(setTriggerStatus(true));
			}
			dispatch(setSidebarTab('trips'));
			
		});
	}

    return (
    	<Modal
			title={
				<div
					style={{
							width: '100%',
							cursor: 'move',
						}}
						onFocus={() => {}}
						onBlur={() => {}}
					>
						Trip Builder
			  		</div>
				}
				visible={modal === 'trip'}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
		            <Button disabled={loading} key="back" onClick={handleCancel}>
		              Cancel
		            </Button>,
		            <Button disabled={loading} key="submit" form="newPropertyForm" type="primary" onClick={form.submit}>
		              Next
		            </Button>
		        ]}
		   >
		   <Form
		   	{...layout}
		   	id="newPostForm"
		    form={form}
		    onFinish={submit}
		   >
			  <Form.Item
		        label="Trip Name"
		        name="name"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <Input/>
		      </Form.Item>
		      <Form.Item
		        label="Description"
		        name="destinations"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <TextArea rows={4}/>
		      </Form.Item>
		      <Form.Item
		        label="Dates"
		        name="dates"
		        rules={[
		          {
		            required: true,
		          }
		        ]}
		      >
		        <RangePicker />
		      </Form.Item>
			</Form>
		 </Modal>
    )
}


export default TripModal;