import {
    SET_SELECTED_GIG, SET_GIGS, INITIAL_GIGS_FETCH
} from '../constants/action-types';

import {
	API_URL
} from '../constants/config';

import axios from 'axios';


export function setSelectedGig(selectedGig) {
	return {
		type: SET_SELECTED_GIG,
		selectedGig: selectedGig
	};
}

export function initialGigsFetch() {
	return {
		type: INITIAL_GIGS_FETCH
	}
}

export function setGigs(gigs) {
	return {
		type: SET_GIGS,
		gigs: gigs
	};
}

export function getGigs(token) {
	return function(dispatch, getState) {
		return axios.get(`${API_URL}/gig`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		}).then(function(data){
			var fc = {
			  "type": "FeatureCollection",
			  "features": data.data.gigs
			};
			dispatch(setGigs(fc));
		});
	}
}

export function createGig(gig, token) {
	return function(dispatch, getState) {
		return axios.post(`${API_URL}/gig`, gig, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  }
		});
	}
}

export function deleteGig(gig_id, token) {
	return function(dispatch, getState) {
		return axios.delete(`${API_URL}/gig`, {
		  headers: {
		    'Authorization': `Bearer ${token}`
		  },
		  params: {
		  	gig_id: gig_id
		  }
		});
	}
}