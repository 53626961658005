import React, { useEffect, useState } from 'react';
import './App.css';
import 'antd/dist/antd.less';
import { Layout } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from './components/sidebar/sidebar.js'
import Home from './components/home/home.js'
import Navbar from './components/navbar/navbar.js'
import { useDispatch } from "react-redux";
import { getSelf } from "./actions/self";
import { setToken } from "./actions/token";

const { Header } = Layout;

const App = () => {

    const {
      getAccessTokenSilently,
      loginWithRedirect,
      isAuthenticated
    } = useAuth0();

    const [fetched, setFetched] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      const fetches = async () => {
        let token = null
        try {
          token = await getAccessTokenSilently();
          setFetched(true);
          dispatch(setToken(token));
          dispatch(getSelf(token));
        } catch (e) {
          console.log(e)
          setTimeout(async function(){
            try {
              token = await getAccessTokenSilently();
              setFetched(true);
              dispatch(setToken(token));
              dispatch(getSelf(token));
            } catch (e) {
              loginWithRedirect();
            }
          }, 3000)
        }
      }
      if(!isAuthenticated){
        fetches();
      }
    // eslint-disable-next-line
    }, [isAuthenticated])


    return (
      <React.Fragment>
      { isAuthenticated ? <div className="App">
        <Layout className="fill" >
          <Header id="header" style={{ position: 'fixed', width: '100%' }}>
            <Navbar />
          </Header>
          <Layout >
            <Sidebar style={{height: '100vh'}}/>
            <Home/>
          </Layout>
        </Layout>
      </div> : null}
      {!fetched ? <div id="fade-wrapper">
        <div className='uil-ring-css' style={{transform:'scale(0.79)'}}>
          <div></div>
        </div>
      </div> : null }
      </React.Fragment>
    )
}

export default App;
